<template>
  <div class="card-details-wrapper">
    <p class="name">{{ object.name }}</p>
    <p>{{ object.description }}</p>
    <ul>
      <price :object="object" :location="cfg.LOCATION_EU" />
      <availability :availability-info="availabilityInfo" />
      <li>
        <div class="btns">
          <b-input-group>
            <b-form-input
              v-model.number="quantity"
              type="number"
              min="1"
              :max="max"
              :disabled="disabled || disableButtonQuantity"
            />
            <b-button
              v-if="!disableButtonQuantity"
              variant="primary"
              class="button"
              :disabled="
                disableButton ||
                  disableButtonInner ||
                  !cookieConsent ||
                  disableButtonQuantity
              "
              @click="addToCart"
            >
              <v-icon dark left>mdi-cart-arrow-down</v-icon>
              {{ cookieConsent ? $t("addToCart") : $t("noCookieConsent") }}
            </b-button>
            <b-button v-else class="button" @click.stop="showEnquiry = true">
              <v-icon dark left>mdi-cart-arrow-down</v-icon>
              {{ $t("sendEnquiry") }}
            </b-button>
          </b-input-group>
        </div>
      </li>
    </ul>

    <!-- SNACKBAR -->
    <v-snackbar v-model="snackbar" top left :timeout="timeout">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">{{ $t("COMMON.CLOSE") }}</v-btn>
    </v-snackbar>

    <!-- ENQUIRY -->
    <enquiry
      :dialog="showEnquiry"
      :part="object.name"
      :manufacturer="object.manufacturer"
      @snack="
        () => {
          snackbarText = $t('DETAILS.ENQUIRY_SENT');
          snackbar = true;
        }
      "
      @modal="
        val => {
          showEnquiry = val;
        }
      "
    />
  </div>
</template>

<script>
import cfg from "./../../3c.config";
import axios3C from "./../../axios";
import { mapState, mapMutations } from "vuex";
import green from "./../../assets/stock-tick.svg";
import red from "./../../assets/stock-cross.svg";
import Price from "./Price";
import Availability from "./Availability";
import Enquiry from "./Enquiry";
import { MANUFACTURER } from "./../../vars";
import api from "@/api2";

const locDictionary = {
  "5e0dbaba9e33df43f0b3a495": "EU warehouse",
  "5e0dbaba9e33df43f0b3a496": "UK warehouse",
  default: "Other"
};

const avlDictionary = {
  "5e0dbaba9e33df43f0b3a495": "Available in EU warehouse",
  "5e0dbaba9e33df43f0b3a496": "Available in UK warehouse",
  manufacturer: "Manufacturer stock",
  default: "Available in other warehouse"
};

const saber = "60c064203f41452f00573371";

export default {
  name: "CardDetailsComponent",
  components: { Enquiry, Availability, Price },
  props: {
    object: Object,
    disableButton: Boolean,
    config: Object
  },
  computed: {
    ...mapState(["cart_id", "cookieConsent", "userCountry", "currency"]),
    disableButtonQuantity() {
      return this.quantityInBasket >= this.warehouseAvl + this.manufacturerAvl;
    },
    max() {
      if (
        this.object.manufacturergroup === MANUFACTURER.DBA ||
        this.object.manufacturergroup === MANUFACTURER.XFORCE
      ) {
        return this.warehouseAvl + this.manufacturerAvl - this.quantityInBasket;
      } else if (this.object.manufacturergroup === MANUFACTURER.ACL) {
        return this.warehouseAvl - this.quantityInBasket;
      } else {
        const object = this.object.available.find(
          loc => loc.location === this.cfg.LOCATION_EU
        );
        return object.specialbackorder
          ? this.warehouseAvl + this.manufacturerAvl - this.quantityInBasket
          : this.warehouseAvl - this.quantityInBasket;
      }
    },
    productType() {
      let ans = "other";
      const name = this.object && this.object.name;
      const excluded = [
        "DBA 52370.1LS",
        "DBA 52370.1RS",
        "DBA 52379BLKLS",
        "DBA 52379BLKRS"
      ];

      if (name) {
        if (excluded.includes(name)) {
          ans = "excluded";
        } else if (
          name.includes("DBA") &&
          (name.includes("SP") || name.includes("XP"))
        ) {
          ans = "kit";
        } else if (name.includes("DBA")) {
          ans = "discbrake";
        } else if (name.includes("DB")) {
          ans = "brakepad";
        }
      }

      return ans;
    },
    disabled() {
      return (
        this.warehouseAvl === 0 &&
        (this.manufacturerAvl === 0 || this.object.custombox) &&
        this.object.pricegroup !== saber
      );
    },
    warehouseAvl() {
      const object = this.object.available.find(
        loc => loc.location === this.cfg.LOCATION_EU
      );
      return object.quantityavailable || 0;
    },
    manufacturerAvl() {
      const obj = this.object.available.find(
        loc => loc.location === "manufacturer"
      );
      if (!obj.quantityavailable || typeof obj.quantityavailable === "string") {
        return 0;
      } else {
        return obj.quantityavailable;
      }
    },
    availabilityInfo() {
      let text = this.$t("DETAILS.AVAILABLE");
      let value = this.$t("DETAILS.DELIVERY_2_4");
      let image = green;
      let tooltipText = "";
      if (
        (this.warehouseAvl === 0 &&
          (this.manufacturerAvl === 0 || this.object.custombox) &&
          this.object.pricegroup !== saber) ||
        this.quantityInBasket >= this.manufacturerAvl + this.warehouseAvl
      ) {
        text = this.$t("DETAILS.OUT_OF_STOCK");
        value = "";
        image = red;
      } else if (
        (this.warehouseAvl === 0 &&
          (this.manufacturerAvl > 0 || this.object.pricegroup === saber)) ||
        this.quantity + this.quantityInBasket > this.warehouseAvl
      ) {
        // text = this.getAirfreightText(this.object.pricegroup);
        text = this.getNewAirFreightText(this.object.manufacturergroup);
        value = "";
        tooltipText =
          this.object.pricegroup === saber
            ? this.$t("DETAILS.TOOLTIP_SABER")
            : this.$t("DETAILS.TOOLTIP");
      }
      return {
        text,
        value,
        image,
        tooltipText
      };
    }
  },
  created() {
    if (this.object.price) {
      const pricesObject = this.object.price.prices;
      this.location = Object.keys(pricesObject).find(
        key => pricesObject[key].preferred
      );
    }
    if (this.cart_id) {
      api.getCarts(this.cart_id, this.axios3C, this.currency).then(res => {
        this.quantityInBasket = res.data.shoppingcarts[0].shoppingcart.transactionlines
          .filter(obj => obj.item._id === this.object.id)
          .map(obj => obj.quantity)
          .reduce((prev, curr) => prev + curr, 0);
      });
    }
  },
  methods: {
    ...mapMutations(["setCartId"]),
    // getAirfreightText(pricegroup) {
    //   let ans = this.$t("DETAILS.DELIVERY_14_21A");
    //   switch (pricegroup) {
    //     case "5e0dbaba9e33df43f0b3a4bb":
    //     case "5e0dbaba9e33df43f0b3a4ba":
    //       ans = this.$t("DETAILS.DELIVERY_14_21A");
    //       break;
    //     case saber:
    //       ans = this.$t("DETAILS.DELIVERY_14_21AF");
    //       break;
    //     case "5e0dbaba9e33df43f0b3a4c0":
    //     case "5e0dbaba9e33df43f0b3a4c1":
    //       ans = this.$t("DETAILS.DELIVERY_7_10A");
    //       break;
    //     case "5e0dbaba9e33df43f0b3a4ce":
    //     case "5e0dbaba9e33df43f0b3a4cf":
    //     case "5e0dbaba9e33df43f0b3a4c5":
    //       ans = this.$t("DETAILS.DELIVERY_7_10");
    //       break;
    //     case "5e0dbaba9e33df43f0b3a4bd":
    //       ans = this.$t("DETAILS.DELIVERY_23_28A");
    //       break;
    //   }
    //   return ans;
    // },

    getNewAirFreightText(manufacturerGroup) {
      let ans = "";
      if (
        [
          "5f9bda55dcdf6b0c04f1878c",
          "5f9bda55dcdf6b0c04f1878b",
          "5f9bda55dcdf6b0c04f18789",
          "60c063843f41452f00573370"
        ].includes(manufacturerGroup)
      ) {
        ans = this.$t("DETAILS.DELIVERY_28");
      } else {
        ans = this.$t("DETAILS.DELIVERY_23_28A");
      }
      return ans;
    },
    wouldOrder(email) {
      this.axios3C
        .post("/item", {
          action: "wouldOrder",
          email
        })
        .then(() => {
          this.$emit("wouldOrderOK");
        })
        .catch(err => {
          this.$emit("cartError", err);
        });
    },
    addToCart() {
      this.disableButtonInner = true;
      const serviceCode =
        this.productType === "brakepad"
          ? "5e2566e63e8d6645e0e62291"
          : "5e0dbaba9e33df43f0b3a4f3";
      let payload = {
        action: "addToCart",
        quantity: this.quantity,
        record: this.object.id,
        location: this.cfg.LOCATION_EU,
        additionalservice:
          this.warehouseAvl === 0 &&
          this.manufacturerAvl > 0 &&
          this.object.price.prices[this.cfg.LOCATION_EU].additionalservices
            .length
            ? [serviceCode]
            : [],
        customer: "guest",
        currency: this.currency,
        country: this.userCountry.code
      };
      if (this.cart_id) {
        payload = { ...payload, cart_id: this.cart_id };
      }
      this.axios3C
        .post("/cart", payload)
        .then(res => {
          this.$store.commit("updateQuantity", res.data.quantity);
          if (!this.cart_id) {
            this.setCartId(res.data.cart_id);
          }
          this.snackbar = true;
        })
        .catch(err => {
          this.$emit("cartError", err);
        })
        .finally(() => {
          this.disableButtonInner = false;
          this.quantityInBasket = this.quantityInBasket + this.quantity;
          this.quantity = 1;
        });
    }
  },
  watch: {
    quantity() {
      if (this.quantity > this.max) {
        this.quantity = 1;
      }
      this.$emit("quantityChanged", this.quantity);
    }
  },
  data: function() {
    return {
      snackbarText: this.$t("DETAILS.ITEM_ADDED"),
      showEnquiry: false,
      axios3C,
      cfg,
      location: "",
      disableButtonInner: false,
      serviceSelected: [],
      timeout: 2000,
      snackbar: false,
      quantity: 1,
      avlDictionary,
      locDictionary,
      showAdditional: false,
      quantityInBasket: 0
    };
  }
};
</script>

<style lang="scss" scoped>
.input-locations,
.services {
  text-align: left;

  .service {
    font-size: 14px;
  }

  div {
    margin-top: 0;
  }

  small {
    padding-left: 35px;
  }
}

.air {
  .air-btn {
    font-family: "Michroma", sans-serif;
    background: url("./../../assets/green.png");
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    font-size: 0.8em;
    height: 75px;
    color: white;
    font-weight: 700;
    word-spacing: 5px;
    padding: 20px 20px 0 50px;

    &-close {
      padding-top: 30px;
    }

    // &:hover {
    //   background: url("./../../assets/gray.png");
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   cursor: pointer;
    // }
  }

  .air-btn-active {
    box-shadow: 0 0 5px 5px var(--primary);
  }
}

.card-details-wrapper {
  // color: rgba($color: #000000, $alpha: 0.6);
  color: black;

  ul {
    padding: 0;
    margin: 10px 0;
    list-style-type: none;

    li {
      // border: 1px solid lightgray;
      border: 1px solid darkgray;

      border-bottom: none;

      .li-flex {
        display: flex;
        justify-content: space-between;
        text-align: left;

        div:last-of-type {
          text-align: right;
        }
      }

      padding: 10px;

      &:last-of-type {
        // border-bottom: 1px solid lightgray;
        border-bottom: 1px solid darkgray;
      }
    }
  }
}
</style>

<style lang="scss">
.form-control {
  border-left: 1px solid darkgray;
  border-top: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
}

.service {
  .v-label {
    font-size: 14px;
  }
}

.input-locations,
.services {
  .v-label {
    margin-bottom: 0;
    color: black;

    &:first-of-type {
      margin-right: 10px;
    }
  }
}
</style>
