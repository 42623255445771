<template>
  <div class="side">
    <div class="header">LATEST NEWS</div>
    <div v-for="item in newsFiltered" :key="item.ID">
      <small>{{ item.date }}</small>
      <router-link
        tag="p"
        v-if="item.content.extended"
        :to="'/news/' + item.post_name"
        class="more-link"
        >{{ item.post_title }}</router-link
      >
      <p v-else>{{ item.post_title }}</p>
    </div>
    <div class="header">NEW PRODUCTS - Xtreme Clutch</div>
    <div class="product-link">
      <a
        target="_blank"
        href="https://drive.google.com/file/d/1bW6_blf3XNtE3eFp7h8bFPGBvFHO4hMc/view"
        >Honda Civic Type-R &amp; Honda Integra Type-R</a
      >
    </div>
    <div class="product-link">
      <a
        target="_blank"
        href="https://drive.google.com/open?id=1ZXUWqWXlZ9bWs4lerkCIFoCYgTyr-dbt"
        >Honda Civic Type-R FK8</a
      >
    </div>
    <div class="product-link">
      <a
        target="_blank"
        href="https://drive.google.com/open?id=1cuTcty1s6boCar4fxxAQYOYezOwTLaQk"
        >Hyundai i30 N</a
      >
    </div>
    <small v-show="!showMore" @click="showMore = !showMore" class="more-link"
      >See more...</small
    >
    <div v-show="showMore">
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=1oUsFKta7B0shv9QGG6ZHYS8Yg2hpuWkJ"
          >Honda Prelude</a
        >
      </div>
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=18Aer9xB-oSUR0VbA8vx41nZciKQEBDrE"
          >Lotus Exige, Evora</a
        >
      </div>
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=1Q1bvrXW7IRTWBPTyroOCmW7oNEjwuydy"
          >Toyota GT86_UK</a
        >
      </div>
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=1PV507G0bObyUkr-RibrPxkFefCYLRSjU"
          >Toyota Hilux Info Sheet</a
        >
      </div>
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=1IGW1AX8qPe_S8NiOCS0aCaCLJ6K5w6nA"
          >XT BMW M3 E90</a
        >
      </div>
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=1xd7nHigiaoQxJsIycJecHJC6NEsRJilM"
          >XT Ford Fiesta ST 2013-2019</a
        >
      </div>
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=18-qmtzfylsS1lTIrkPH2BnrRIhYLQpOr"
          >XT Ford Fiesta XR4, ST150</a
        >
      </div>
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=1V2RIEe9NpQTI73l5G5NzHzQdADpCGKIA"
          >XT Renault Megane RS</a
        >
      </div>
      <div class="product-link">
        <a
          target="_blank"
          href="https://drive.google.com/open?id=1mPkWGQgNnQgazD4A7MtDAdV_9vU9a1mQ"
          >XT Suzuki Swift Sport 2018</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideNews",
  props: {
    newsFiltered: Array
  },
  data() {
    return {
      showMore: false
    };
  }
};
</script>

<style lang="scss" scoped>
.side {
  padding: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;

  .product-link {
    margin-bottom: 14px;
  }

  .header {
    margin-bottom: 20px;
    font-weight: 500;
  }

  .more-link:hover {
    cursor: pointer;
  }
}
</style>
