<template>
  <v-progress-circular indeterminate class="loader"></v-progress-circular>
</template>

<script>
export default {
  name: "TheLoader"
};
</script>

<style lang="scss" scoped>
.loader {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}
</style>
