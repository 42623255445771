<template>
  <div class="shipping-wrapper">
    <div class="main-item-header">{{ $t("BASKET.SHIPPING_ADDRESS") }}</div>
    <div class="grid">
      <b-form-group :invalid-feedback="veeErrors.first('address')">
        <b-form-input
          id="input-1"
          v-validate="'required'"
          name="address"
          type="text"
          :placeholder="$t('BASKET.STREET')"
          :value="value.address"
          :state="validateState('address')"
          @change="val => handleChange(val, 'address')"
        ></b-form-input>
      </b-form-group>
      <b-form-group :invalid-feedback="veeErrors.first('address')">
        <b-form-input
          v-validate="'required'"
          name="number"
          type="text"
          :placeholder="$t('BASKET.NUMBER')"
          :value="value.number"
          :state="validateState('number')"
          @change="val => handleChange(val, 'number')"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-input
          id="input-2"
          type="text"
          :placeholder="$t('BASKET.APARTMENT')"
          :value="value.address2"
          @change="val => handleChange(val, 'address2')"
        ></b-form-input>
      </b-form-group>

      <b-form-group :invalid-feedback="veeErrors.first('city')">
        <b-form-input
          id="input-3"
          v-validate="'required'"
          type="text"
          :placeholder="$t('BASKET.CITY')"
          name="city"
          :state="validateState('city')"
          :value="value.city"
          @change="val => handleChange(val, 'city')"
        ></b-form-input>
      </b-form-group>

      <b-form-group :invalid-feedback="veeErrors.first('zip')">
        <b-form-input
          id="input-5"
          v-validate="'required'"
          autocomplete="nope"
          name="zip"
          type="text"
          :placeholder="$t('BASKET.ZIP')"
          :value="value.zip"
          :state="validateState('zip')"
          @change="val => handleChange(val, 'zip')"
        ></b-form-input>
      </b-form-group>

      <b-form-group :invalid-feedback="veeErrors.first('country')">
        <b-form-select
          v-validate="'required'"
          name="country"
          :value="value.country"
          :state="validateState('country')"
          :options="countriesForm"
          @change="val => handleChange(val, 'country')"
        ></b-form-select>
      </b-form-group>

      <b-form-group :invalid-feedback="veeErrors.first('shippingMethod')">
        <b-form-select
          v-validate="'required'"
          name="shippingMethod"
          :value="value.shippingMethod"
          :state="validateState('shippingMethod')"
          :options="shippingMethodsExtended"
          value-field="_id"
          :text-field="'displayname' || 'name'"
          @change="val => handleChange(val, 'shippingMethod')"
        ></b-form-select>
      </b-form-group>

      <b-form-group :invalid-feedback="veeErrors.first('paymentMethod')">
        <b-form-select
          v-validate="'required'"
          name="paymentMethod"
          :value="value.paymentMethod"
          :state="validateState('paymentMethod')"
          :options="paymentMethodsExtended"
          value-field="_id"
          text-field="displayname"
          @change="val => handleChange(val, 'paymentMethod')"
        ></b-form-select>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import countries from "@/countries";

export default {
  name: "ShippingAddress",
  props: {
    value: {
      type: Object,
      required: true
    },
    shippingMethods: {
      type: Array,
      default: () => []
    },
    paymentMethods: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    shippingMethodsExtended() {
      return [
        {
          _id: "",
          displayname: this.$t("BASKET.SHIPPING_METHOD"),
          disabled: true
        },
        ...this.shippingMethods
      ];
    },
    paymentMethodsExtended() {
      return [
        {
          _id: "",
          displayname: this.$t("BASKET.PAYMENT_METHOD"),
          disabled: true
        },
        ...this.paymentMethods
      ];
    },
    countriesForm() {
      const items = Object.keys(countries).map(key => ({
        value: key,
        text: countries[key]
      }));
      return [
        { value: "", text: this.$t("BASKET.COUNTRY"), disabled: true },
        ...items
      ];
    }
  },
  methods: {
    handleChange(value, field) {
      this.$emit("input", { ...this.value, [field]: value });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-item-header {
  background-color: black;
  color: white;
  font-size: 16px;
  padding: 5px 15px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: "Michroma", sans-serif;
}

.selected {
  line-height: 12px;
  padding-left: 40px;

  @media screen and (max-width: 960px) {
    padding-left: 0;
    padding-top: 20px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
}
</style>
