<template>
  <div class="faq-wrapper">
    <page-header text="faq" font="white" back="black"></page-header>
    <b-container>
      <f-eu v-if="i18n.locale === 'en'" />
      <!--      <f-uk v-if="$source === 'ozpartsuk' && i18n.locale === 'en'" />-->
      <f-pl v-if="i18n.locale === 'pl'" />
    </b-container>
  </div>
</template>

<script>
import i18n from "./../i18n";

import PageHeader from "@/components/common/PageHeader.vue";
import FEu from "@/components/faq/FAQ_EU.vue";
// import FUk from "@/components/faq/FAQ_UK.vue";
import FPl from "@/components/faq/FAQ_PL.vue";

export default {
  name: "FAQView",
  data: () => ({
    i18n
  }),
  components: {
    PageHeader,
    FEu,
    FPl
  }
};
</script>
