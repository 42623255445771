<template>
  <div class="tire-wrapper">
    <b-container>
      <div class="tire-content">
        <div class="my-row normal">
          <div
            class="row-item"
            v-for="brand in row1"
            :key="brand.name"
            @click="goToBrand(brand.destination)"
          >
            <div
              class="brand"
              :style="{ backgroundImage: `url(${brand.image})` }"
            ></div>
            <img :src="brand.logo" :alt="brand.name" class="brand-image" />
          </div>
        </div>
        <div class="my-row reversed">
          <div
            class="row-item"
            v-for="brand in row2"
            :key="brand.name"
            @click="goToBrand(brand.destination)"
          >
            <div
              class="brand"
              :style="{ backgroundImage: `url(${brand.image})` }"
            ></div>
            <img :src="brand.logo" :alt="brand.name" class="brand-image" />
          </div>
        </div>
        <div class="my-row normal">
          <div
            class="row-item"
            v-for="brand in row3"
            :key="brand.name"
            @click="goToBrand(brand.destination)"
          >
            <div
              class="brand"
              :style="{ backgroundImage: `url(${brand.image})` }"
            ></div>
            <img :src="brand.logo" :alt="brand.name" class="brand-image" />
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import redarcLogo from "./../../assets/tire/redarc-logo.png";
import redarc from "./../../assets/tire/redarc.png";
import turbosmartLogo from "./../../assets/tire/turbosmart-logo.png";
import turbosmart from "./../../assets/tire/turbosmart.jpg";
import xoutbackLogo from "./../../assets/tire/xoutback-logo.png";
import xoutback from "./../../assets/tire/xoutback.jpg";
import whitelineLogo from "./../../assets/tire/whiteline-logo.png";
import whiteline from "./../../assets/tire/whiteline.png";
import aclLogo from "./../../assets/tire/acl-logo.png";
import acl from "./../../assets/tire/acl.png";
import peddersLogo from "./../../assets/tire/pedders-logo.png";
import pedders from "./../../assets/tire/pedders.jpg";
import hawkLogo from "./../../assets/tire/hawk-logo.png";
import hawk from "./../../assets/tire/hawk.png";
import dbaLogo from "./../../assets/tire/dba-logo.png";
import dba from "./../../assets/tire/dba.jpg";
import saberLogo from "./../../assets/tire/saber-logo.png";
import rycoLogo from "./../../assets/tire/Ryco.gif";
import saber from "./../../assets/tire/saber-background.png";
import ryco from "./../../assets/tire/ryco.png";

export default {
  name: "Tire",
  methods: {
    goToBrand(location) {
      this.$router.push(location);
    }
  },
  data: () => ({
    row1: [
      {
        logo: saberLogo,
        image: saber,
        name: "Saber",
        destination: "/brands/saber"
      },
      {
        logo: redarcLogo,
        image: redarc,
        name: "Redarc",
        destination: "/brands/redarc"
      },
      {
        logo: turbosmartLogo,
        image: turbosmart,
        name: "Turbosmart",
        destination: "/brands/turbosmart"
      }
    ],
    row2: [
      {
        logo: xoutbackLogo,
        image: xoutback,
        name: "Xtreme Outback",
        destination: "/brands/xoutback"
      },
      {
        logo: whitelineLogo,
        image: whiteline,
        name: "Whiteline",
        destination: "/brands/whiteline"
      },
      { logo: aclLogo, image: acl, name: "ACL", destination: "/brands/acl" },
      { logo: rycoLogo, image: ryco, name: "RYCO", destination: "/brands/ryco" }
    ],
    row3: [
      {
        logo: peddersLogo,
        image: pedders,
        name: "Pedders",
        destination: "/brands/pedders"
      },
      {
        logo: hawkLogo,
        image: hawk,
        name: "Hawk",
        destination: "/brands/hawk"
      },
      { logo: dbaLogo, image: dba, name: "DBA", destination: "/brands/dba" }
    ]
  })
};
</script>

<style scoped lang="scss">
.my-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-bottom: 15px;
    flex-direction: row;
  }
}

.normal {
  .row-item {
    clip-path: polygon(50% 10%, 100% 0, 100% 90%, 49% 100%, 0 90%, 0 0);
    @media (min-width: 768px) {
      clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    }
  }
}

.reversed {
  .row-item {
    clip-path: polygon(50% 10%, 100% 0, 100% 90%, 49% 100%, 0 90%, 0 0);
    @media (min-width: 768px) {
      clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 10% 100%, 0% 50%, 10% 0);
    }
  }
}

.row-item {
  height: 144px;
  width: 100%;
  position: relative;
  margin-right: -2%;

  &:hover {
    cursor: pointer;
    .brand {
      filter: grayscale(0);
    }
  }

  .brand {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: grayscale(1);

    &-image {
      position: absolute;
      width: 60%;
      padding: 40px 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
