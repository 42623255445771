<template>
  <div>
    <div class="header-wrapper-exception osram-banner">
      <h2>Osram</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Osram Quality and Engineering
          </p>
          <p>
            OSRAM‘s LED driving and working lights offer the most sophisticated
            LED technology and electrical design on the market. Products are
            available in three categories (functional, multifunctional and slim)
            offering a variety of performances, shapes and beam patterns with
            various illumination profiles like spot, wide and combo. Products
            are available for 12 and 24 voltage vehicles.
          </p>
          <p class="bolder">
            Product Features
          </p>
          <ul>
            <li>
              Different high beam patterns (spot, wide or combo) with various
              beam angles available
            </li>
            <li>Powerful far and near field illumination up to 450 m</li>
            <li>
              Extremely high optic efficiency, homogeneous light distribution
              and reduced glaring
            </li>
            <li>Premium OSRAM quality</li>
            <li>
              Robust and compact design with long lifetime of up to 5,000 h
              thanks to the integrated driver and thermal management system
            </li>
          </ul>
          <p class="bolder">
            Benefits
          </p>
          <ul>
            <li>Improved illumination thanks to high brightness</li>
            <li>
              Better visibility due to daylight effect for increased road safety
            </li>
            <li>Lighting up the intended illumination area</li>
            <li>
              5-year guarantee (Refer to www.osram.com/am-guarantee for precise
              conditions)
            </li>
            <li>
              Extremely robust polycarbonate lens to endure heavy-duty
              application, multiple voltage protection
            </li>
          </ul>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/osram.webp"
            />
            <img src="./../../assets/brands-logos/osram.png" alt="Osram" />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header text="Important Links" font="black" back="white"></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/osram">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <!-- <a href="https://ozparts.eu/pdf/RSAG20.pdf" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>-->
        <a href="http://www.osram.com/ledriving-spotlights" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <!--<a
          href="https://drive.google.com/open?id=1Jb_IprCzcQOPPbcK0U1iAHIY0e8THldk"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>-->
        <a
          href="https://www.youtube.com/channel/UCd5fB8C8OJJlVJPuH16F7hA"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/BAFId5H9xcA"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Osram</p>
          <p>
            Conquer the dark with OSRAM’s LED driving and working lights. OSRAM
            LED driving and working lights offer the most sophisticated LED
            technology and electrical design for on- and off-road applications
            and everywhere in between.
          </p>
        </div>
      </div>
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "../home/Tire";

export default {
  name: "OsramEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
