<template>
  <div class="about-view-wrapper">
    <about-en v-if="lang === 'en'" />
    <about-pl v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AboutEn from "@/components/about/AboutEN.vue";
import AboutPl from "@/components/about/AboutPL.vue";

export default {
  name: "AboutView",
  components: { AboutEn, AboutPl },
  computed: {
    ...mapState(["lang"])
  }
};
</script>

<style lang="scss">
.about-view-wrapper {
  box-sizing: border-box;

  .level {
    .text {
      padding: 3em;
      font-family: "Montserrat", sans-serif;
      line-height: 1.5;
      white-space: normal;
      max-width: 600px;
      justify-self: end;

      h3 {
        font-size: 16px;
        font-family: "Michroma", sans-serif;
        line-height: 1.5;
        word-spacing: 5px;
      }

      p:first-of-type {
        font-size: 40px;
        font-weight: bold;
      }
    }

    .image {
      img {
        height: 30em;
        width: 100%;
        display: block;
        object-fit: cover;
      }
    }

    @media screen and (min-width: 960px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .image {
        img {
          height: 100%;
          clip-path: polygon(15% 0%, 100% 0, 100% 100%, 0% 100%);
        }
      }

      & + .flipped {
        grid-template-areas: "left right";
        .text {
          grid-area: right;
          justify-self: start;
        }
        .image {
          grid-area: left;
          img {
            clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
          }
        }
      }
    }
  }
}
</style>
