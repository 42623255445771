<template>
  <div>
    <div class="additional">
      <div class="additional-header">{{ $t("ORDER.PAY_BY_REVOLUT") }}</div>
      <div class="revolut-placeholder">
        <div class="widget" @click="handleRevolutPayment">
          <img src="@/assets/revolut-logo.png" />
        </div>
      </div>
      <modal-loader v-model="modalLoader" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RevolutCheckout from "@revolut/checkout";
import ModalLoader from "@/components/common/ModalLoader.vue";

export default {
  name: "Revolut",
  props: { order: Object },
  components: { ModalLoader },
  data() {
    return {
      modalLoader: false
    };
  },
  methods: {
    async handleRevolutPayment() {
      const hostname = window.location.hostname;
      const dev = ["dev.oz4x4.eu", "localhost"];
      const isDev = dev.includes(hostname);
      const urlBase = "https://api.ozparts.eu";
      const url = `${urlBase}/payments/revolut/eu`;
      const data = {
        amount: isDev ? "0.01" : this.order.grossamount,
        currency: this.order.currency.iso,
        region: "eu"
      };

      try {
        const response = await axios.post(url, data);
        RevolutCheckout(response.data.public_id).then(instance => {
          instance.payWithPopup({
            name: this.order.shipaddressee,
            billingAddress: {
              countryCode: this.order.billcountry,
              city: this.order.billcity,
              postcode: this.order.billzip,
              streetLine1: this.order.billaddressee,
              streetLine2: `${this.order.billaddress} ${this.order.billaddress2}`
            },
            onSuccess: () => this.$emit("success", { status: "COMPLETED" }),
            onError(message) {
              alert(message);
            }
          });
        });
      } catch (error) {
        this.$emit("error", error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.additional {
  .additional-header {
    background-color: black;
    color: white;
    padding: 5px 15px;
    font-family: "Michroma", sans-serif;
    font-size: 14px;
    margin: 20px 0;
  }

  .revolut-placeholder {
    height: 150px;
    width: auto;

    .widget,
    img {
      height: 150px;
    }
    .widget {
      display: flex;
      justify-content: center;
    }
    img:hover {
      cursor: pointer;
      filter: brightness(80%);
    }
  }
}
</style>
