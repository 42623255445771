<template>
  <div>
    <div class="header-wrapper-exception xtreme-banner">
      <h2>Xtreme Clutch</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Xtreme Clutch has been at the forefront of performance clutch
            technology for decades. In house testing machinery and close bonds
            with motorsport has allowed Xtreme Clutch to do extensive testing on
            various combinations of materials and disc designs to suit high
            performance and racing applications.
          </p>
          <p>
            The Xtreme Clutch name has become synonymous with high performance.
            Xtreme has earned its reputation as Australasia’s largest
            manufacturer and stockist of clutch kits and components. Your Xtreme
            Clutch has been professionally engineered and developed as a brand
            new product resulting in a clutch that exceeds application
            requirements for performance, wear and driveability in the long
            term.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_xtreme.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_xtreme.png"
              alt="Xtreme Clutch"
            />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header
      text="Important Links"
      font="black"
      back="#fafafa"
    ></page-header>

    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/xclutch">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/open?id=1605Ck2GoRlUUfdtf-v3U1b2HuZOlLaTK"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>
        <a href="https://xtremeclutch.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/open?id=1NT1VL94gTTVv86gyEVXw_Az_eDEnPw1b'
              : 'https://drive.google.com/open?id=1759GE7hGRoH82PPUrqVgt987k1zHqnuz'
          "
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("vehicleInformationSheets") }}
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1605Ck2GoRlUUfdtf-v3U1b2HuZOlLaTK'
              : 'https://drive.google.com/open?id=1459hK2QQsNYz7EugGVLkK2dqxo8D7vHg'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCRfZxyw0nqAwcmbZmPlkEnA"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/-8zOERnXUzI?si=PgASOkQu0srO7jr1"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Australian Clutch Services: Corporate Profile</p>
          <p>
            Australian Clutch Services specialises in the supply and manufacture
            of a wide range of standard replacement and performance upgrade
            clutch kits and components to suit thousands of vehicles. The range
            covers passenger car, commercial, marine, forklift and agricultural
            equipment as well as specialty applications such as classic and
            vintage vehicles. Also included in the range are a wide range of
            accessories and hydraulics to suit thousands of vehicles.
          </p>
          <p>For more information, please visit www.australianclutch.com.au</p>
        </div>
      </div>
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "@/components/common/PageHeader.vue";
import Tire from "../home/Tire";

export default {
  name: "BrandXClutchEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
