<template>
  <v-app>
    <nav class="white">
      <nav-desktop class="hidden-sm-and-down" />
      <nav-mobile class="hidden-md-and-up" />
    </nav>
    <marquee-component />
    <v-main class="white">
      <router-view :key="currency || 'eur'" />
    </v-main>
    <cookie />
    <footer-section />
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
import NavDesktop from "./components/navigation/desktop/Desktop";
import NavMobile from "./components/navigation/mobile/Mobile";
import FooterSection from "./components/Footer";
import Cookie from "@/components/Cookie";
import MarqueeComponent from "./components/home/Marquee.vue";
import { loadHotJarScript, loadGtmScript } from "./scripts/index";

const events = ["mousemove", "touchstart", "touchmove", "click"];

export default {
  name: "App",
  components: {
    Cookie,
    NavDesktop,
    NavMobile,
    FooterSection,
    MarqueeComponent
  },
  async created() {
    if (location.pathname === "/") {
      const onEventTriggered = () => {
        this.loadScripts();
        events.forEach(event =>
          window.removeEventListener(event, onEventTriggered)
        );
      };

      events.forEach(event => window.addEventListener(event, onEventTriggered));

      this.$once("hook:beforeDestroy", () => {
        events.forEach(event =>
          window.removeEventListener(event, onEventTriggered)
        );
      });
    } else {
      this.loadScripts();
    }

    try {
      const res = await axios.get("https://api.ozparts.eu/country");
      this.setUserCountry(res.data);
    } catch (e) {
      this.setUserCountry({ code: "PL", country: "Poland" });
    }
  },
  computed: {
    ...mapState(["currency"])
  },
  methods: {
    ...mapMutations(["setUserCountry"]),
    loadScripts() {
      loadHotJarScript();
      loadGtmScript();
    }
  }
};
</script>

<style lang="scss">
body {
  --red: #ed3730;
  --primary: #ff5d03;
  --secondary: #2f2f2f;

  line-height: 1.5;
  white-space: normal;
  font-family: "Montserrat", sans-serif;
}
.nav-button {
  font-family: "Michroma", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0.5em;
  margin: 0 0.4em;
  transition-duration: 0.5s;

  &:hover {
    background-color: var(--primary);
    color: white;
    cursor: pointer;
    transition-duration: 0.2s;
  }
}

.nav-active {
  background-color: var(--primary);
  color: white;
}

.container {
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
