export const fields = {
  pl: {
    note: {
      key: "note",
      label: "Szczegóły"
    },
    description: {
      key: "description",
      label: "Szczegóły"
    },
    make: {
      key: "make",
      label: "Marka"
    },
    model: "model",
    year: {
      key: "year",
      label: "Rocznik"
    },
    peryear: {
      key: "peryear",
      label: "Rocznik"
    },
    variant: {
      key: "variant",
      label: "Wariant"
    },
    engine: {
      key: "engine",
      label: "Silnik"
    },
    class: {
      key: "classdescription",
      label: "Oś"
    },
    category: {
      key: "categorydescription",
      label: "Kategoria"
    },
    group: {
      key: "groupdescription",
      label: "Grupa"
    },
    parameter: {
      key: "parameter",
      label: "Parametry"
    }
  },
  en: {
    description: {
      key: "description",
      label: "Details"
    },
    note: {
      key: "note",
      label: "Note"
    },
    make: "make",
    model: "model",
    year: "year",
    peryear: {
      key: "peryear",
      label: "Year"
    },
    variant: "variant",
    engine: "engine",
    class: {
      key: "classdescription",
      label: "Axle"
    },
    category: {
      key: "categorydescription",
      label: "Category"
    },
    group: {
      key: "groupdescription",
      label: "Group"
    },
    parameter: {
      key: "parameter",
      label: "Parameter"
    }
  }
};
