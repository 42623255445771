<template>
  <div>
    <div class="icon">
      <img
        v-if="i18n.locale === 'en'"
        class="icon-item"
        src="../../../../assets/pl512.png"
        alt="Polish"
        @click="() => updateConfig('pl')"
      />
      <img
        v-if="i18n.locale === 'pl'"
        class="icon-item"
        src="../../../../assets/uk512.png"
        alt="English"
        @click="() => updateConfig('en')"
      />
    </div>
    <ChangeCurrencyLocale
      :changeCurrency="this.showChangeCurrencyModal"
      :languageVersion="this.lang"
      @clicked="updateCurrency"
    />
  </div>
</template>

<script>
import i18n from "../../../../i18n";
import { mapMutations, mapState } from "vuex";
import ChangeCurrencyLocale from "./ChangeCurrencyLocale";

export default {
  name: "Locale",
  data: function() {
    return {
      i18n,
      showChangeCurrencyModal: false
    };
  },
  components: {
    ChangeCurrencyLocale
  },
  mounted() {
    this.setLang(this.lang || i18n.locale);
  },
  computed: {
    ...mapState(["cart_id", "lang", "currency"])
  },
  methods: {
    ...mapMutations(["setLang", "setCurrency"]),
    async updateCurrency(change, curr) {
      if (change) {
        this.setCurrency(curr === "pl" ? "pln" : "eur");
      }
      this.showChangeCurrencyModal = false;
    },

    updateConfig: async function(flagClicked) {
      if (
        this.currency !== (flagClicked === "pl" ? "pln" : "eur") &&
        !window.location.pathname.split("/").includes("order")
      ) {
        this.showChangeCurrencyModal = true;
      }
      this.setLang(flagClicked);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  z-index: 200;
  margin-left: 15px;
}

.icon-item {
  width: 30px;
  z-index: 200;

  &:hover {
    cursor: pointer;
  }
}
</style>
