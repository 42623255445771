<template>
  <div class="news-wrapper">
    <page-header :text="$t('news')" back="lightgray" font="black" />
    <b-container>
      <news-item v-for="item in news" :key="item.ID" :news="item" />
      <div class="news-image" v-if="news.lenght > 0">
        <img src="./../../assets/ozparts10_e3blue.png" alt />
      </div>
    </b-container>
  </div>
</template>

<script>
import NewsItem from "@/components/home/NewsItem.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "NewsSegment",
  components: { NewsItem, PageHeader },
  props: {
    news: Array
  }
};
</script>

<style lang="scss">
.news-wrapper {
  font-family: "Michroma", sans-serif;
  box-sizing: border-box;
  position: relative;

  .news-image {
    display: none;
  }

  @media screen and (min-width: 960px) {
    @supports (clip-path: polygon(15% 0%, 100% 0, 100% 100%, 0% 100%)) {
      .news-image {
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 0;

        img {
          display: block;
          height: 350px;
        }
      }
    }
  }
}
</style>
