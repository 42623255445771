import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const scrollBehavior = to => {
  if (to.hash) {
    return { selector: to.hash, offset: { x: 0, y: 20 } };
  } else if (to.name === "catalogue") {
    return {
      x: 0,
      y: 200,
      behavior: "smooth"
    };
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

router.beforeEach((to, from, next) => {
  if (to.path === "/brands") {
    next("/#brands");
  } else {
    next();
  }
});

export default router;
