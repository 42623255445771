<template>
  <div>
    <div class="header-wrapper-exception xforce-banner">
      <h2>Xforce</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            XFORCE focuses strongly on research and development which ensures
            that their di-verse range of exhaust products are of powerful
            engineering precision, stylish and practi-cally designed to maximize
            performance and most importantly, technically innovative such as our
            patent VAREX™ Muffler application which in itself, is an exhaust
            revolution.
          </p>
          <p>
            The focus on innovation and extensive quality control enables XFORCE
            to provide you with outstanding performance values when it comes to
            your exhaust. XFORCE is heavily involved in continuous research and
            development that combines powerfully with their manufacturing
            advantage in exhaust production and automotive engineering. Their
            ex-haust systems are specially designed and dyno tested by our
            state-of-the-art Dynapack Evolution 3000 chassis dynamometer
            machine, for proven maximum performance in Xforce R &amp; D centre
            in Australia.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_xforce.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_xforce.png"
              alt="Xforce"
            />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header text="Important Links" font="black" back="white"></page-header>

    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/xforce">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/open?id=1yZ0t1JXD-EuWlG5MNIYHvqKDDIYrOo4I"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>
        <a href="http://xforce.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1ADhJnEp_xscrVlb3BH86ad3axo8B2NsX'
              : 'https://drive.google.com/open?id=1bPT7svkaHpD4pYJGf7aB3PE1jK9yysBs'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a href="https://www.youtube.com/user/xforceexhaust" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/xcfTcwAjNLU"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>XForce Engine-Back Exhaust System for Ford Mustang GT 5.0L 2015</p>
          <p>
            Here's our very own 2015 Mustang GT fitted with the new XForce
            1-7/8" long tube header and 3" stainless catback with adjustable
            Varex rear mufflers. Have a listen!
          </p>
          <p>
            Now available from your local authorised XForce dealer:
            http://www.xforce.com.au/where-to-buy
          </p>
        </div>
      </div>
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "@/components/common/PageHeader.vue";
import Tire from "../home/Tire";

export default {
  name: "XforceEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
