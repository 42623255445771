var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side"},[_c('div',{staticClass:"header"},[_vm._v("LATEST NEWS")]),_vm._l((_vm.newsFiltered),function(item){return _c('div',{key:item.ID},[_c('small',[_vm._v(_vm._s(item.date))]),(item.content.extended)?_c('router-link',{staticClass:"more-link",attrs:{"tag":"p","to":'/news/' + item.post_name}},[_vm._v(_vm._s(item.post_title))]):_c('p',[_vm._v(_vm._s(item.post_title))])],1)}),_c('div',{staticClass:"header"},[_vm._v("NEW PRODUCTS - Xtreme Clutch")]),_vm._m(0),_vm._m(1),_vm._m(2),_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMore),expression:"!showMore"}],staticClass:"more-link",on:{"click":function($event){_vm.showMore = !_vm.showMore}}},[_vm._v("See more...")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMore),expression:"showMore"}]},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/file/d/1bW6_blf3XNtE3eFp7h8bFPGBvFHO4hMc/view"}},[_vm._v("Honda Civic Type-R & Honda Integra Type-R")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1ZXUWqWXlZ9bWs4lerkCIFoCYgTyr-dbt"}},[_vm._v("Honda Civic Type-R FK8")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1cuTcty1s6boCar4fxxAQYOYezOwTLaQk"}},[_vm._v("Hyundai i30 N")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1oUsFKta7B0shv9QGG6ZHYS8Yg2hpuWkJ"}},[_vm._v("Honda Prelude")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=18Aer9xB-oSUR0VbA8vx41nZciKQEBDrE"}},[_vm._v("Lotus Exige, Evora")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1Q1bvrXW7IRTWBPTyroOCmW7oNEjwuydy"}},[_vm._v("Toyota GT86_UK")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1PV507G0bObyUkr-RibrPxkFefCYLRSjU"}},[_vm._v("Toyota Hilux Info Sheet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1IGW1AX8qPe_S8NiOCS0aCaCLJ6K5w6nA"}},[_vm._v("XT BMW M3 E90")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1xd7nHigiaoQxJsIycJecHJC6NEsRJilM"}},[_vm._v("XT Ford Fiesta ST 2013-2019")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=18-qmtzfylsS1lTIrkPH2BnrRIhYLQpOr"}},[_vm._v("XT Ford Fiesta XR4, ST150")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1V2RIEe9NpQTI73l5G5NzHzQdADpCGKIA"}},[_vm._v("XT Renault Megane RS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-link"},[_c('a',{attrs:{"target":"_blank","href":"https://drive.google.com/open?id=1mPkWGQgNnQgazD4A7MtDAdV_9vU9a1mQ"}},[_vm._v("XT Suzuki Swift Sport 2018")])])
}]

export { render, staticRenderFns }