<template>
  <div>
    <div class="header-wrapper-exception redarc-banner">
      <h2>Saber</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Saber test all of its products to their limits and beyond. The vast
            majority of the gear is tested to destruction in a NATA (National
            Association of Testing Authorities) Lab. Saber also takes its gear
            into the real world to make sure it will work in the harshest
            conditions that can be found.
          </p>
          <p>
            You’ll see batch numbers and contact details on all Saber products
            and that’s your way of knowing they were tested to destruction. More
            than that, Saber products meet all applicable standards and are
            always labelled accordingly. Because Saber wants you to be as
            confident in their products as they are.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/saber.webp"
            />
            <img src="./../../assets/brands-logos/saber.png" alt="Saber" />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header text="Important Links" font="black" back="white"></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/saber">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <!-- <a
          href="https://drive.google.com/open?id=1QW4Skw4bcB7Xn0tCX7b5gUCfL1bvZb_5"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a> -->
        <a href="https://saberoffroad.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <!-- <a
          href="https://drive.google.com/open?id=1u8haAFgezQ6y3yNMuxwvbwVo3Kla9QgC"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>New Products In Stock
          </div>
        </a> -->
        <a
          href="https://drive.google.com/drive/folders/1OTPl2CUx2sTXHNVfa5kC0ycdrcIViu-P"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <!-- <a
          href="https://www.youtube.com/user/RedarcElectronics"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a> -->
      </div>
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "@/components/home/Tire";

export default {
  name: "SaberEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
