<template>
  <div>
    <div class="header-wrapper-exception redarc-banner">
      <h2>Redarc</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Electronics jest firmą, która projektuje, produkuje i dostarcza
            opatentowane, niezawodne i w pełni zintegrowane, przenośne,
            samochodowe systemy zasilania oraz usługi posprzedażne
            skoncentrowane na potrzebach klienta.
          </p>
          <p>
            REDARC posiada ponad 35-letnie doświadczenie w badaniach,
            projektowaniu, rozwoju i produkcji szerokiej gamy elektronicznych
            przetwornic napięcia i związanych z nimi produktów takich jak
            falowniki, zasilacze i ładowarki akumulatorów.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_redarc.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_redarc.png"
              alt="Whiteline"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/redarc">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon
            >{{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/open?id=1QW4Skw4bcB7Xn0tCX7b5gUCfL1bvZb_5"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="https://www.redarc.com.au/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          href="https://drive.google.com/drive/folders/1jRVh3NR-akFrQJpjsZHDPDL3vflqb8ak"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/user/RedarcElectronics"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/x2iiN9bkGvQ"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>The Story of REDARC CEO &amp; Managing Director Anthony Kittel</p>
          <p>Discover the journey undertaken by Anthony Kittel towards owning and managing REDARC Electronics. His passion and dedication to the company have helped transform REDARC into one of Australia's leading manufacturers.</p>
          <p>Learn more at https://www.redarc.com.au/about</p>
        </div>
      </div>-->
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "../home/Tire";

export default {
  name: "RedarcPl",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
