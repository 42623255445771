<template>
  <div class="news-view-wrapper">
    <page-header text="News" font="white" back="black" />
    <b-container>
      <div class="news-content-wrapper">
        <div class="main">
          <page-header text="Latest news" font="black" back="white" />
          <news-item v-for="(item, index) in items" :key="index" :news="item" />
        </div>
        <side-news :newsFiltered="items" />
      </div>
    </b-container>
  </div>
</template>

<script>
import { axiosWP } from "../axios";
import NewsItem from "@/components/home/NewsItem.vue";
import SideNews from "@/components/news/SideNews.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "NewsView",
  components: {
    NewsItem,
    PageHeader,
    SideNews
  },
  computed: {
    newsFiltered() {
      return this.items.filter(item => item.show);
    }
  },
  data() {
    return {
      items: [],
      postAmount: 4
    };
  },
  async created() {
    const news = await axiosWP.get("/news/" + this.postAmount);
    this.items = news.data;
  }
};
</script>

<style lang="scss">
.news-content-wrapper {
  display: grid;
  grid-template-columns: 4fr 1fr;
}
</style>
