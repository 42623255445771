<template>
  <div class="mt-4">
    <b-form-checkbox
      id="terms"
      ref="terms"
      v-validate="'required'"
      name="terms"
      :checked="value"
      @change="handleChange"
    >
      <i18n path="BASKET.ACCEPT_TERMS" tag="span">
        <template #terms>
          <router-link to="/terms">{{ $t("BASKET.TERMS") }}</router-link>
        </template>
      </i18n>
    </b-form-checkbox>
    <b-form-invalid-feedback :state="validateState('terms')">{{
      veeErrors.first("terms")
    }}</b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: "TermsAcceptance",
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleChange(value) {
      this.$emit("input", value);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
