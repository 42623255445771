<template lang="pug">
    div(class="terms-wrapper")
        terms-en(v-if="lang === 'en'")
        terms-pl(v-if="lang === 'pl'")
</template>

<script>
import { mapState } from "vuex";
import TermsEn from "@/components/terms/TermsEn.vue";
import TermsPl from "@/components/terms/TermsPl.vue";

export default {
  name: "TermsView",
  components: { TermsEn, TermsPl },
  computed: {
    ...mapState(["lang"])
  }
};
</script>

<style lang="scss">
.terms-wrapper {
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 16px;
  }

  .header {
    line-height: 1.5;
    word-spacing: 5px;
    margin: 20px 0;
    font-family: "Michroma", sans-serif;
    font-size: medium;
  }
}
</style>
