<template>
  <article>
    <page-header text="Polityka 'Cookies'" font="white" back="black" />
    <b-container>
      <h2>CO TO SĄ PLIKI "COOKIES"</h2>
      <p>
        Portal oz4x4.pl używa plików cookies (tzw. „ciasteczek”). Cookies to
        pliki zawierające różne informacje, które są zapisywane na Twoim
        urządzeniu (komputerze, smartfonie itp.) w czasie odwiedzin stron
        internetowych. Nasz portal wysyła je do Twojej przeglądarki, która je
        następnie wysyła z powrotem przy następnych wejściach na witrynę. Dzięki
        plikom cookies Portal oz4x4.pl rozpoznaje cię i pamięta na przykład
        twoje preferencje (odwiedziny, kliknięcia, poprzednie działania).
      </p>
      <p>
        Pliki cookies umożliwiają dostosowanie działania stron do preferencji
        użytkowników. Dzięki plikom cookies jesteśmy w stanie doskonalić i
        ulepszać Portal, tak aby korzystanie z nich było jeszcze łatwiejsze i
        wygodniejsze.
      </p>
      <h2>W JAKIM CELU KORZYSTAMY W PLIKÓW "COOKIES"?</h2>
      <p>Pliki cookies wykorzystywane są przez Portal oz4x4.pl w celu:</p>
      <ol>
        <li>
          <p>
            dostosowania zawartości stron do preferencji osoby odwiedzającej
            Portal oraz optymalizacji korzystania ze strony; w szczególności
            pliki te pozwalają rozpoznać urządzenie osoby odwiedzającej Portal i
            odpowiednio wyświetlić stronę, dostosowaną do jej indywidualnych
            potrzeb;
          </p>
        </li>
        <li>
          <p>
            zbierania statystyk, które informują o tym w jaki sposób osoby
            odwiedzające Portal korzystają ze stron internetowych, co umożliwia
            ulepszanie ich struktury i zawartości - analiza tych statystyk jest
            anonimowa i umożliwia dostosowanie zawartości i wyglądu Portalu do
            panujących trendów, statystyki stosuje się też do oceny popularności
            strony;
          </p>
        </li>
        <li>
          <p>
            utrzymania sesji osoby odwiedzającej Portal przed zalogowaniem,
            dzięki czemu Portal pamięta zawartość koszyka i dodanych do niego
            produktów oraz po zalogowaniu, nie trzeba na każdej podstronie
            ponownie wpisywać loginu i hasła,
          </p>
        </li>
        <li>
          <p>
            zapewnienia prawidłowego funkcjonowania chatu udostępnionego przez
            podmiot zewnętrzny tj. Live Agent.
          </p>
        </li>
      </ol>
      <h2>JAKIE RODZAJE PLIKÓW COOKIES WYKORZYSTUJEMY?</h2>
      <p>
        Rodzajów plików cookies jest bardzo wiele. Najłatwiej opisać je
        uwzględniając ich cechy charakterystyczne.
      </p>
      <p>Pliki "cookies" możemy podzielić ze względu na:</p>
      <ol>
        <li>
          <p>ich niezbędność do korzystania z naszego Portalu:</p>
          <ol>
            <li>
              <p>
                Niezbędne – jak mówi nazwa, są one niezbędne do prawidłowego
                funkcjonowania Portalu oz4x4.pl oraz funkcjonalności z których
                chcesz skorzystać. Bez tego rodzaju „cookies” niemożliwe byłoby
                dokonywanie zakupów w naszym sklepie internetowym. Niektóre z
                nich zapewniają także bezpieczeństwo usług świadczonych przez
                nas drogą elektroniczną.
              </p>
            </li>
            <li>
              <p>
                Funkcjonalne – nie są niezbędne dla funkcjonowania Portalu,
                jednakże ich wyłączenie spowoduje, że strona nie będzie
                dostosowywana do indywidualnych preferencji użytkowników, a
                niektóre funkcje nie będą działały
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>okres przez jaki „cookies” będą umieszczone na twoim urządzeniu</p>
          <ol>
            <li>
              <p>
                Cookies sesyjne - pozostają w przeglądarce internetowej
                urządzenia do momentu jej wyłączenia, bądź wyjścia ze strony
                internetowej, na której zostały zamieszczone;
              </p>
              <p>
                Cookies stałe (persistent cookies) - nie są kasowane po
                zamknięciu przeglądarki i pozostają w urządzeniu do momentu ich
                usunięciu przez użytkowania bądź do z góry
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>podmiot, który zarządza plikami cookies</p>
          <ol>
            <li>
              <p>
                Cookies własne - cookies” umieszczone na stronach internetowych
                bezpośrednio przez Ozparts spółka z ograniczoną
                odpowiedzialnością spółka komandytowa.
              </p>
            </li>
            <li>
              <p>
                Cookies zewnętrzne - "cookies" umieszczane na stronach
                internetowych przez podmioty trzecie, w związku ze stosowaniem
                przez nas z narzędzi takich jak Paypal do uiszczania płatności i
                wykorzystywaniem uzyskanych w ten sposób informacji. W przypadku
                wywołania przez Portal komponentów stron internetowych
                pochodzących spoza systemu oz4x4.pl mogą obowiązywać inne
                standardowe zasady polityki „cookies” niż niniejsza polityka
                „cookies”. Zachęcamy do zapoznania się z politykami cookies
                podmiotów, z których narzędzi korzystamy:<br />Google Llc. –
                <a href="https://policies.google.com/technologies/cookies"
                  >https://policies.google.com/technologies/cookies</a
                ><br />Vimeo Llc. –
                <a href="https://vimeo.com/cookie_policy"
                  >https://vimeo.com/cookie_policy</a
                ><br />Facebook Inc. –
                <a href="https://www.facebook.com/policies/cookies"
                  >https://www.facebook.com/policies/cookies</a
                ><br />Quality Unit Llc. (LiveAgent) –
                <a href="https://www.liveagent.com/privacy-policy/"
                  >https://www.liveagent.com/privacy-policy/</a
                ><br />PayPal Holdings Inc. –
                <a
                  href="https://www.paypal.com/myaccount/profile/flow/cookies/?locale=pl"
                  >https://www.paypal.com/myaccount/profile/flow/cookies/?locale=pl</a
                >
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>cel, jakiemu służą pliki „cookies”</p>
          <ol>
            <li>
              <p>
                Konfiguracja stron internetowych - umożliwiają ustawienia
                funkcji i usług na stronach internetowych.
              </p>
            </li>
            <li>
              <p>
                Bezpieczeństwo i niezawodność stron internetowych - umożliwiają
                weryfikację autentyczności oraz optymalizację wydajności stron
                internetowych.
              </p>
            </li>
            <li>
              <p>
                Uwierzytelnianie - umożliwiają informowanie, gdy użytkownik jest
                zalogowany, dzięki czemu strona internetowa może pokazywać
                odpowiednie informacje i funkcje.
              </p>
            </li>
            <li>
              <p>
                Stan sesji - umożliwiają zapisywanie informacji o tym, jak
                użytkownicy korzystają ze strony internetowej. Mogą one dotyczyć
                najczęściej odwiedzanych stron lub ewentualnych komunikatów o
                błędach wyświetlanych na niektórych stronach. Pliki „cookies”
                służące do zapisywania tzw. „stanu sesji” pomagają ulepszać
                usługi i zwiększać komfort przeglądania stron.
              </p>
            </li>
            <li>
              <p>
                Procesy - umożliwiają sprawne działanie strony internetowej oraz
                dostępnych na niej funkcji.
              </p>
            </li>
            <li>
              <p>
                Analizy i badania, audyt oglądalności - umożliwiają
                właścicielowi stron internetowych lepiej zrozumieć preferencje
                ich użytkowników i poprzez analizę ulepszać i rozwijać produkty
                i usługi. Zazwyczaj właściciel strony internetowej lub firma
                badawcza zbiera anonimowo informacje i przetwarza dane na temat
                trendów, bez identyfikowania danych osobowych poszczególnych
                użytkowników,
              </p>
            </li>
            <li>
              <p>
                Marketing i reklama – umożliwiają nam przedstawienie
                spersonalizowanych reklam naszych produktów przy wykorzystaniu
                sieci reklamowych, które używają ciasteczek pochodzących od
                podmiotów zewnętrznych. Dzięki cookies nasze reklamy wyświetlają
                się w innych serwisach internetowych osobom, które są
                zainteresowane naszymi produktami. Są one aktywowane, gdy
                odwiedzasz naszą witrynę internetową i są odczytywane wyłącznie
                przez właściwego partnera reklamowego (np. Facebook czy
                YouTube).
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>zakres ingerencji w prywatność użytkownika</p>
          <ol>
            <li>
              <p>
                Nieszkodliwe – są to cookies niezbędne do poprawnego działania
                strony internetowej oraz potrzebne do umożliwienia działania
                funkcjonalności strony internetowej
              </p>
            </li>
            <li>
              <p>
                Badające - - wykorzystywane do śledzenia użytkowników, jednak
                nie obejmują informacji pozwalających (bez innych danych)
                zidentyfikować konkretnego użytkownika
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <table class="cookie-table">
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Dostawca</th>
            <th>Cel</th>
            <th>Data ważności</th>
            <th>Rodzaj</th>
            <th>Typ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>__paypal_storage__</td>
            <td>paypal.com</td>
            <td>Umożliwia płatności</td>
            <td>Trwały</td>
            <td>HTML Local Storage</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>basketCount</td>
            <td>oz4x4.pl</td>
            <td>Przechowuje dane o wielkości koszyka</td>
            <td>Sesja</td>
            <td>HTTP Cookie</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>cart_id</td>
            <td>oz4x4.pl</td>
            <td>Przechowuje identyfikator koszyka zakupowego</td>
            <td>Sesja</td>
            <td>HTTP Cookie</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>consent</td>
            <td>oz4x4.pl</td>
            <td>Przechowuje zgody użytkownika (jeśli wyrażono)</td>
            <td>Trwały</td>
            <td>HTML Local Storage</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>SesjaID</td>
            <td>paypal.com</td>
            <td>Umożliwia płatności</td>
            <td>3 lata</td>
            <td>HTTP Cookie</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>enforce_policy</td>
            <td>paypal.com</td>
            <td>Umożliwia płatności</td>
            <td>1 rok</td>
            <td>HTTP Cookie</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>l7_az</td>
            <td>paypal.com</td>
            <td>Umożliwia płatności</td>
            <td>1 dzień</td>
            <td>HTTP Cookie</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>ts_c</td>
            <td>paypal.com</td>
            <td>Umożliwia płatności</td>
            <td>3 lata</td>
            <td>HTTP Cookie</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>ts</td>
            <td>paypal.com</td>
            <td>Umożliwia płatności</td>
            <td>Sesja</td>
            <td>Pixel Tracker</td>
            <td>Niezbędne</td>
          </tr>
          <tr>
            <td>LaSID</td>
            <td>liveagent.com</td>
            <td>Umożliwia komunikację bezpośrednią za pośrednictwem chata</td>
            <td>Sesja</td>
            <td>HTTP Cookie</td>
            <td>Statystyczne</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>google.com</td>
            <td>
              Rejestruje unikalne ID służące do monitorowania aktywności
              użytkownika na stronie
            </td>
            <td>2 lata</td>
            <td>HTTP Cookie</td>
            <td>Statystyczne</td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>google.com</td>
            <td>Optymalizuje ilość zapytań do serwera</td>
            <td>1 dzień</td>
            <td>HTTP Cookie</td>
            <td>Statystyczne</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>google.com</td>
            <td>
              Rejestruje unikalne ID służące do monitorowania aktywności
              użytkownika na stronie
            </td>
            <td>1 dzień</td>
            <td>HTTP Cookie</td>
            <td>Statystyczne</td>
          </tr>
          <tr>
            <td>LaVisitorNew</td>
            <td>liveagent.com</td>
            <td>Umożliwia komunikację bezpośrednią za pośrednictwem chata</td>
            <td>1 dzień</td>
            <td>HTTP Cookie</td>
            <td>Statystyczne</td>
          </tr>
          <tr>
            <td>vuid</td>
            <td>vimeo.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>2 lata</td>
            <td>HTTP Cookie</td>
            <td>Statystyczne</td>
          </tr>
          <tr>
            <td>_fbp</td>
            <td>facebook.com</td>
            <td>
              Wykorzystywane przez Facebooka do parowania treści reklamowych
            </td>
            <td>3 miesiące</td>
            <td>HTTP Cookie</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>LaVisitorId_b3pwYXJ0cy5sYWRlc2suY29tLw</td>
            <td>liveagent.com</td>
            <td>Umożliwia komunikację bezpośrednią za pośrednictwem chata</td>
            <td>Sesja</td>
            <td>HTTP Cookie</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>IDE</td>
            <td>google.com</td>
            <td>
              Wykorzystywane przez Google do monitorowania interakcji z
              przedmiotami reklamowanymi za pośrednictwem przeglądarki
            </td>
            <td>1 rok</td>
            <td>HTTP Cookie</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>test_cookie</td>
            <td>google.com</td>
            <td>Sprawdza, czy przeglądarka obsługuje ciasteczka</td>
            <td>1 dzień</td>
            <td>HTTP Cookie</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>fr</td>
            <td>facebook.com</td>
            <td>
              Wykorzystywane przez Facebooka do parowania treści reklamowych
            </td>
            <td>3 miesiące</td>
            <td>HTTP Cookie</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>tr</td>
            <td>facebook.com</td>
            <td>
              Wykorzystywane przez Facebooka do parowania treści reklamowych
            </td>
            <td>Sesja</td>
            <td>Pixel Tracker</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>VISITOR_INFO1_LIVE</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>179 dni</td>
            <td>HTTP Cookie</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>YSC</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Sesja</td>
            <td>HTTP Cookie</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>yt.innertube::nextId</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Trwały</td>
            <td>HTML Local Storage</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>yt.innertube::requests</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Trwały</td>
            <td>HTML Local Storage</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>yt-remote-cast-installed</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Sesja</td>
            <td>HTML Local Storage</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>yt-remote-connected-devices</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Trwały</td>
            <td>HTML Local Storage</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>yt-remote-device-id</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Trwały</td>
            <td>HTML Local Storage</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>yt-remote-fast-check-period</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Sesja</td>
            <td>HTML Local Storage</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>yt-remote-Sesja-app</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Sesja</td>
            <td>HTML Local Storage</td>
            <td>Marketingowe</td>
          </tr>
          <tr>
            <td>yt-remote-Sesja-name</td>
            <td>youtube.com</td>
            <td>
              Przechowuje preferencje użytkownika względem ustawień odtwarzacza
              wideo
            </td>
            <td>Sesja</td>
            <td>HTML Local Storage</td>
            <td>Marketingowe</td>
          </tr>
        </tbody>
      </table>
      <h2>JAKIE DANE ZBIERAJĄ PLIKI „COOKIES”? CZY SĄ TO DANE OSOBOWE?</h2>
      <ol>
        <li>
          <p>
            Gromadzone informacje dotyczą w szczególności adresu IP, typu
            wykorzystywanej przeglądarki, języka, rodzaju systemu operacyjnego,
            dostawcy usług internetowych, informacji o czasie i dacie,
            lokalizacji.
          </p>
        </li>
        <li>
          <p>
            Co do zasady pliki „cookies” nie stanowią danych osobowych. Jednak
            pewne informacje przechowywane w plikach „cookies” (np. co do
            preferencji), zwłaszcza w połączeniu z innymi informacjami o
            użytkowniku stron internetowych, mogą być traktowane jako dane
            osobowe. Dane osobowe gromadzone przy użyciu plików „cookies” mogą
            być przetwarzane wyłącznie w celu wykonywania określonych funkcji na
            rzecz użytkownika, opisanych powyżej. Takie dane są zaszyfrowane w
            sposób uniemożliwiający dostęp do nich osobom nieuprawnionym
          </p>
        </li>
        <li>
          <p>
            Aby skorzystać z prawa sprzeciwu wobec przetwarzania takich danych,
            które przyznaje RODO, konieczne jest wylogowanie się ze wszystkich
            urządzeń i usunięcie z nich plików cookies.
          </p>
        </li>
      </ol>
      <h2>JAK ZARZĄDZAĆ PLIKAMI COOKIES?</h2>
      <ol>
        <li>
          <p>
            W wielu przypadkach oprogramowanie służące do przeglądania stron
            internetowych (przeglądarka internetowa) domyślnie dopuszcza
            przechowywanie plików cookies w urządzeniu końcowym osoby
            odwiedzającej Portal. W takim wypadku można dokonać w każdym czasie
            zmiany ustawień dotyczących plików cookies. Ustawienia te mogą
            zostać zmienione w szczególności w taki sposób, aby blokować
            automatyczną obsługę plików cookies w ustawieniach przeglądarki
            internetowej bądź informować o ich każdorazowym zamieszczeniu w
            urządzeniu osoby odwiedzającej Portal. Szczegółowe informacje o
            możliwości i sposobach obsługi plików cookies dostępne są w
            ustawieniach oprogramowania (przeglądarki internetowej).
          </p>
        </li>
        <li>
          <p>
            Szczegółowe informacje o zarządzaniu plikami cookie dla niektórych
            przeglądarek:<br />
            Firefox:
            <a href="https://support.mozilla.org/pl/kb/ciasteczka"
              >https://support.mozilla.org/pl/kb/ciasteczka</a
            ><br />
            Chrome:
            <a href="https://support.google.com/chrome/answer/95647?hl=pl"
              >https://support.google.com/chrome/answer/95647?hl=pl</a
            ><br />
            Edge:<a
              href="https://support.microsoft.com/pl-pl/topic/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d"
              >https://support.microsoft.com/pl-pl/topic/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d</a
            ><br />
            Safari:
            <a href="https://support.apple.com/kb/ph21411?locale=pl_PL"
              >https://support.apple.com/kb/ph21411?locale=pl_PL</a
            ><br />Opera:
            <a href="https://help.opera.com/Windows/12.10/pl/cookies.html"
              >https://help.opera.com/Windows/12.10/pl/cookies.html</a
            >
          </p>
        </li>
        <li>
          <p style="text-decoration: underline;">
            Administrator informuje, że ograniczenia stosowania plików cookies
            mogą wpłynąć na niektóre funkcjonalności dostępne na stronach
            internetowych Portalu.
          </p>
        </li>
        <li>
          <p>
            Jeśli chcesz wiedzieć więcej, na temat tego, czym są pliki cookie i
            w jaki sposób działają, można znaleźć na stronach
            <a href="https://pl.wikipedia.org/wiki/Ciasteczko"
              >https://pl.wikipedia.org/wiki/Ciasteczko</a
            >
            lub
            <a href="https://pl.wikipedia.org/wiki/Ciasteczko"
              >https://wszystkoociasteczkach.pl/</a
            >.
          </p>
        </li>
      </ol>
    </b-container>
  </article>
</template>

<script>
import PageHeader from "@/components/common/PageHeader";

export default {
  name: "CookiesPl",
  components: {
    PageHeader
  }
};
</script>

<style scoped></style>
