<template>
  <div>
    <div class="header-wrapper-exception redarc-banner">
      <h2>Ryco</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p class="font-weight-bold">
            Filozofia projektowania firmy Ryco skupia się na osiągnięciu lub
            przewyższeniu właściwości standardowego produktu. Filtry Ryco
            zapewniają wydajność opartą na efektywności i żywotności.
          </p>
          <p>
            Przekraczając standardy produkcyjne dzięki innowacyjnemu projektowi,
            Ryco zajmuje pozycję lidera w dziedzinie filtrów samochodowych. Ryco
            testuje swoje filtry w specjalistycznym laboratorium, co napędza do
            nieustannego poszukiwania lepszych rozwiązań w dynamicznie
            ewoluującym sektorze.
          </p>
          <p>
            Przez ponad 80 lat Ryco utrzymuje pozycję lidera w dziedzinie
            projektowania i rozwoju systemów filtracyjnych, dostosowując się do
            najbardziej wymagających warunków. Dzięki temu firma wspiera sukces
            swoich partnerów, niezależnie od tego, czy są to pojazdy osobowe,
            użytkowe, 4X4 czy Supercars.
          </p>
          <p>Jeśli więc szukasz najlepszej filtracji, postaw na Ryco.</p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/ryco.webp"
            />
            <img src="./../../assets/brands-logos/ryco.webp" alt="Ryco" />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/ryco">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a href="https://rycofilters.com.au/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon> {{ $t("website") }}
          </div>
        </a>
        <a href="https://www.youtube.com/@RycoFiltersOfficial" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Kanał Youtube
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/L--BQ0vJrvQ?si=J5zmjkeYzzzvKNVe"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Ryco 4WD Service Kits</p>
          <p>
            Our 4WD Service Kits make it easy to buy all the filters your 4x4
            needs in one convenient kit. Designed to meet the demanding needs of
            4x4 vehicles in the most challenging environments, our superior
            filters provide added protection for harsh driving conditions.
          </p>
          <p>
            To find out more about our 4WD Service kits visit
            www.rycofilters.com.au/service-kits
          </p>
        </div>
      </div>
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "@/components/home/Tire";

export default {
  name: "SaberPl",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
