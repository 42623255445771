<template>
  <div>
    <div class="header-wrapper-exception hawk-banner">
      <h2>Hawk Performance</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Hawk Performance is a leading supplier of severe duty and
            high-performance friction products manufactured in ISO-certified
            facilities for the motorsports and performance automotive
            aftermarket.
          </p>
          <p>
            Hawk Performance products are engineered to allow users to control,
            command, and conquer the terrain for which their vehicles are
            designed. Decades of experience in developing friction materials for
            aerospace, industrial, military, automotive and motorsports
            applications give Hawk Performance an advantage over the
            competition. In the past decade, Hawk Performance friction products
            have won more motorsports championships than any other friction
            manufacturer.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_hawk.webp"
            />
            <img src="./../../assets/brands-logos/p10_hawk.png" alt="Hawk" />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header text="Important Links" font="black" back="white"></page-header>

    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/hawk">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1T_98CLuZQaG6wAl2GBQ8_B37dJUrvdsS/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>
        <a href="http://www.hawkperformance.com/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1HaVf181vCpotxqaa9IJy3yw9Ydi9YkFc'
              : 'https://drive.google.com/open?id=1tgmcFQxng_a5i_4DSyF-fFwVq9UXtV0T'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a href="https://www.youtube.com/user/HAWKPERFORMANCE" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/sttWds7JtWY"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>GOODWOOD Festival of Speed - Budweiser Indy 500 Car</p>
          <p>
            We are proud to have had our 1978 Lightning Cosworth attend the 2014
            Goodwood Festival of Speed, it is such an iconic event. Sit back,
            relax, and enjoy an exclusive look inside the Festival of Speed.
          </p>
          <p>*Listen with headphones*</p>
        </div>
      </div>
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "@/components/common/PageHeader.vue";
import Tire from "../home/Tire";

export default {
  name: "HawkEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
