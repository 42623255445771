import { render, staticRenderFns } from "./CookiesPolicy.vue?vue&type=template&id=3d4f4c42&scoped=true&"
import script from "./CookiesPolicy.vue?vue&type=script&lang=js&"
export * from "./CookiesPolicy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_pug@2.0.4_vue-template-compiler@2.7.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4f4c42",
  null
  
)

export default component.exports