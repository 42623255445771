import Home from "../views/Home.vue";
import FAQ from "../views/FAQ.vue";
import Privacy from "../views/Privacy.vue";
import News from "../views/News.vue";
import NewsItem from "../views/NewsItem.vue";
import Terms from "../views/Terms.vue";
import About from "../views/About.vue";
import BrandDBA from "../views/brands/BrandDBA.vue";
import BrandOsram from "../views/brands/BrandsOsram.vue";
import BrandXClutch from "../views/brands/BrandXClutch.vue";
import BrandXOutback from "../views/brands/BrandXOutback.vue";
import BrandXforce from "../views/brands/BrandXforce.vue";
import BrandHawk from "../views/brands/BrandHawk.vue";
import BrandPedders from "../views/brands/BrandPedders.vue";
import BrandTurbosmart from "../views/brands/BrandTurbosmart.vue";
import BrandWhiteline from "../views/brands/BrandWhiteline.vue";
import BrandRedarc from "../views/brands/BrandRedarc.vue";
import BrandACL from "../views/brands/BrandACL.vue";
import Contact from "../views/Contact.vue";
import Basket from "../views/Basket.vue";
import Order from "../views/Order.vue";
import Details from "../views/Details.vue";
import Search from "../views/Search.vue";
import StockClearance from "../views/StockClearance";
import CookiesPolicy from "@/views/CookiesPolicy";
import BrandSaber from "@/views/brands/BrandSaber";
import BrandRyco from "@/views/brands/BrandRyco";

export default [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/osram",
    name: "osram",
    component: StockClearance
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy
  },
  {
    path: "/cookies-policy",
    name: "Cookies",
    component: CookiesPolicy
  },
  {
    path: "/news/:friendly",
    name: "news-view",
    component: NewsItem
  },
  {
    path: "/news",
    name: "news",
    component: News
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms
  },
  {
    path: "/about",
    name: "about",
    component: About
  },
  {
    path: "/brands/dba",
    name: "brand-dba",
    component: BrandDBA
  },
  {
    path: "/brands/saber",
    name: "brand-saber",
    component: BrandSaber
  },
  {
    path: "/brands/osram",
    name: "brand-osram",
    component: BrandOsram
  },
  {
    path: "/brands/xclutch",
    name: "brand-xclutch",
    component: BrandXClutch
  },
  {
    path: "/brands/xoutback",
    name: "brand-xoutback",
    component: BrandXOutback
  },
  {
    path: "/brands/xforce",
    name: "brand-xforce",
    component: BrandXforce
  },
  {
    path: "/brands/hawk",
    name: "brand-hawk",
    component: BrandHawk
  },
  {
    path: "/brands/pedders",
    name: "brand-pedders",
    component: BrandPedders
  },
  {
    path: "/brands/turbosmart",
    name: "brand-turbosmart",
    component: BrandTurbosmart
  },
  {
    path: "/brands/whiteline",
    name: "brand-whiteline",
    component: BrandWhiteline
  },
  {
    path: "/brands/redarc",
    name: "brand-redarc",
    component: BrandRedarc
  },
  {
    path: "/brands/acl",
    name: "brand-acl",
    component: BrandACL
  },
  {
    path: "/brands/ryco",
    name: "brand-ryco",
    component: BrandRyco
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact
  },
  {
    path: "/catalogue/:brand?",
    name: "catalogue",
    component: () =>
      import(/* webpackChunkName: "catalogue" */ "./../views/Catalogue.vue")
  },
  {
    path: "/stock-clearance/:brand?",
    name: "stock-clearance",
    component: () =>
      import(
        /* webpackChunkName: "deadstock-catalogue" */ "./../views/Catalogue.vue"
      )
  },
  {
    path: "/basket",
    name: "basket",
    component: Basket
  },
  {
    path: "/order/:id",
    name: "order",
    component: Order
  },
  {
    path: "/:id",
    name: "details",
    component: Details
  },
  {
    path: "/search/:phrase",
    name: "search",
    component: Search
  }
];
