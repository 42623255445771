import { vatValidator } from "@/validators";
import { Validator } from "vee-validate";

Validator.extend("vat", vatValidator, { paramNames: ["cart_id", "country"] });

export default {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
};
