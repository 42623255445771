<template>
  <div class="marquee" v-if="marquee && marquee.show">
    <marquee v-if="i18n.locale === 'en'">{{
      marquee.text_en || $t("noMarqueeText")
    }}</marquee>
    <marquee v-if="i18n.locale === 'pl'">{{
      marquee.text_pl || $t("noMarqueeText")
    }}</marquee>
  </div>
</template>

<script>
import i18n from "../../i18n";
import axios from "axios";
export default {
  name: "MarqueeComponent",
  data: () => ({
    i18n,
    marquee: {
      show: false,
      text_pl: "",
      text_en: ""
    }
  }),
  async created() {
    this.setMarqueeInfo();
  },
  methods: {
    async setMarqueeInfo() {
      const urlBase = "https://api.ozparts.eu";
      const website = "oz4x4";
      const url = `${urlBase}/marquees/${website}`;

      try {
        const res = await axios.get(url);
        if (res.data.show) {
          this.marquee = res.data;
        }
      } catch {
        alert("Something went wrong with banner!");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.marquee {
  background-color: var(--red);
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 2px 0;
  margin-bottom: -5px;
}
</style>
