<template>
  <h2 class="text-xs-center header">{{ $t("BASKET.EMPTY") }}</h2>
</template>

<script>
export default {
  name: "EmptyBasket"
};
</script>

<style lang="scss" scoped>
.header {
  word-spacing: 5px;
  margin: 20px 0;
  text-align: center;
}
</style>
