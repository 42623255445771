<template>
  <div class="contact-wrapper">
    <div class="contact-header-wrapper">
      <h2>{{ $t("contactUs") }}</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="contact-items">
        <div class="contact-item">
          <p>{{ $t("registeredOffice") }}:</p>
          <p>({{ $t("billingPurposes") }})</p>
          <p>
            OZPARTS PL sp. z o.o.
            <br />ul. Słowackiego 32/5
            <br />
            87-100, Torun, {{ $t("poland") }} <br />VAT: PL 956 223 01 29
            <br />VAT: UK 339 9525 56
          </p>
        </div>
        <div class="contact-item">
          <p>{{ $t("officeAndWarehouse") }}:</p>
          <p class="hidden-p">_</p>
          <p>
            OZPARTS PL sp. z o.o.
            <br />ul. Mazowiecka 46-48
            <br />
            87-100, Torun, {{ $t("poland") }}
          </p>
        </div>
        <div class="contact-item">
          <p>{{ $t("openingHours") }}:</p>
          <p class="hidden-p">_</p>
          <p>
            {{ $t("openingHours1") }}
            <br />
            <br />
            {{ $t("openingHours2") }}
          </p>
        </div>
      </div>
    </b-container>
    <v-tabs centered class="tabs">
      <v-tab class="tabs-header">GENERAL ENQUIRIES FORM</v-tab>
      <v-tab-item>
        <form-general />
      </v-tab-item>
      <v-tab class="tabs-header">DEALER / DISTRIBUTOR ENQUIRY FORM</v-tab>
      <v-tab-item>
        <form-dealer />
      </v-tab-item>
    </v-tabs>
    <!-- <page-header text="GENERAL ENQUIRIES FORM" font="black" back="#dbdcde" /> -->
    <!-- <page-header text="DEALER / DISTRIBUTOR ENQUIRY FORM" font="white" back="#ed3730" /> -->
  </div>
</template>

<script>
import FormGeneral from "@/components/forms/FormGeneral.vue";
import FormDealer from "@/components/forms/FormDealer.vue";
// import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "ContactView",
  components: { FormGeneral, FormDealer },
  data() {
    return {
      active: 0
    };
  }
};
</script>

<style lang="scss">
.contact-wrapper {
  font-family: "Michroma", sans-serif;

  .tab-active {
    font-weight: 900;
  }

  .tabs {
    font-family: "Montserrat", sans-serif;

    &-header {
      letter-spacing: 2px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .contact-header-wrapper {
    text-transform: uppercase;
    background-position: center;
    text-align: center;
    background-size: cover;
    // margin: 2em auto;
    color: white;
    padding: 1em;
    box-shadow: 0 2px 10px 0 rgba(47, 47, 47, 0.3);

    h2 {
      text-shadow: 0 0 10px #2f2f2f;
      padding-top: 10px;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.5;
      word-spacing: 5px;
    }

    .line {
      display: block;
      height: 1px;
      border: 0;
      border-top: 2px solid white;
      margin: 1em auto;
      padding: 0;
      width: 7em;
    }
  }

  .contact-items {
    .contact-item {
      margin: 1.8em 0;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      font-family: "Montserrat", sans-serif;
      .hidden-p {
        visibility: hidden;
      }
      p:first-of-type {
        font-family: "Michroma", sans-serif;
        margin-bottom: 8px;
      }
    }

    @media screen and (min-width: 960px) {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      grid-column-gap: 1em;
    }
  }
}
</style>

<style>
.no-webp .contact-header-wrapper {
  background-image: url("./../assets/bcg-contact.jpg");
}

.webp .contact-header-wrapper {
  background-image: url("./../assets/bcg-contact.webp");
}
</style>
