<template>
  <div>
    <div class="header-wrapper-exception redarc-banner">
      <h2>Redarc</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Based in South Australia, REDARC is an advanced electronics
            manufacturer celebrating 40 years in research, design, development
            and manufacture of a range of electronic voltage converters, power
            supplies, battery chargers, brake controllers and trailer braking
            products.
          </p>
          <p>
            REDARC offer a comprehensive product portfolio featuring
            industry-leading technological and innovative excellence together
            with outstanding customer service and support.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_redarc.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_redarc.png"
              alt="Whiteline"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header text="Important Links" font="black" back="white"></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/redarc">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/open?id=1QW4Skw4bcB7Xn0tCX7b5gUCfL1bvZb_5"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>
        <a href="https://www.redarc.com.au/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a
          href="https://drive.google.com/drive/folders/1jRVh3NR-akFrQJpjsZHDPDL3vflqb8ak"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/user/RedarcElectronics"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/x2iiN9bkGvQ"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>The Story of REDARC CEO &amp; Managing Director Anthony Kittel</p>
          <p>
            Discover the journey undertaken by Anthony Kittel towards owning and
            managing REDARC Electronics. His passion and dedication to the
            company have helped transform REDARC into one of Australia's leading
            manufacturers.
          </p>
          <p>Learn more at https://www.redarc.com.au/about</p>
        </div>
      </div>
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "../home/Tire";

export default {
  name: "RedarcEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
