<template>
  <div>
    <div class="header-wrapper-exception redarc-banner">
      <h2>Saber</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Saber testuje wszystkie swoje produkty do granic możliwości i nie
            tylko. Zdecydowana większość sprzętu jest testowana do momentu
            destrukcji w laboratorium NATA (National Association of Testing
            Authorities). Saber przenosi również swój sprzęt do realnych świata,
            aby upewnić się, że będzie działał w najtrudniejszych warunkach,
            jakich tylko można doświadczyć w terenie.
          </p>
          <p>
            Każdy produkt Saber jest oznakowany numerem partii świadczącym o
            tym, że został on przetestowany do pracy w ekstremalnych warunkach
            do momentu jego destrukcji. Co więcej, produkty Saber spełniają
            wszystkie obowiązujące normy i są zawsze odpowiednio oznakowane.
            Ponieważ chcemy, abyś był tak samo pewny naszych produktów, jak my
            jesteśmy ich pewni.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/saber.webp"
            />
            <img src="./../../assets/brands-logos/saber.png" alt="Saber" />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/saber">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <!-- <a
          href="https://drive.google.com/open?id=1QW4Skw4bcB7Xn0tCX7b5gUCfL1bvZb_5"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a> -->
        <a href="https://saberoffroad.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon> {{ $t("website") }}
          </div>
        </a>
        <!-- <a
          href="https://drive.google.com/open?id=1u8haAFgezQ6y3yNMuxwvbwVo3Kla9QgC"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>New Products In Stock
          </div>
        </a> -->
        <a
          href="https://drive.google.com/drive/folders/1OTPl2CUx2sTXHNVfa5kC0ycdrcIViu-P"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <!-- <a
          href="https://www.youtube.com/user/RedarcElectronics"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a> -->
      </div>
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "@/components/home/Tire";

export default {
  name: "SaberPl",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
