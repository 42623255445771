const SOURCE = window.location.hostname === "oz4x4.uk" ? "oz4x4uk" : "oz4x4eu";

let SPECIAL_DELIVERY_COUNTRIES = ["MT", "CY", "PL"];
export const OSRAM_SHIPPING_METHOD = "5f1fe2e9643c7c417c20e1f5";
const OSRAM_PRICEGROUP = "5e0dbaba9e33df43f0b3a4c6";
let CURRENCY = "eur";
let URL_BASE = "https://oz4x4.eu";
let LOCATION_EU = "5e0dbaba9e33df43f0b3a495"; // EU
let LOCATION_UK = "5e0dbaba9e33df43f0b3a496"; // UK
export const PAYMENT_METHODS = {
  REVOLUT: "revolut",
  PAYPAL: "607468324e5b797a767fe87d"
};

if (SOURCE === "oz4x4uk") {
  CURRENCY = "gbp";
  URL_BASE = "https://ozperformance.co.uk";
}

export default {
  OSRAM_PRICEGROUP,
  OSRAM_SHIPPING_METHOD,
  SPECIAL_DELIVERY_COUNTRIES,
  SOURCE,
  CURRENCY,
  URL_BASE,
  LOCATION_EU,
  LOCATION_UK
};
