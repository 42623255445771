<template>
  <div>
    <div class="header-wrapper-exception turbosmart-banner">
      <h2>Turbosmart</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Turbosmart is one of the world’s leading manufacturer of turbo
            accessories. An ever-increasing number of racing teams and tuning
            houses around the world use and recommend Turbosmart products.
          </p>
          <p>
            Their achievements on the track and at street events are a testimony
            to Turbosmart’s design, manufacturing and testing principles. The
            feedback received from the racers and tuners alike contributes to
            the on-going development of Turbosmart products, reinforcing the
            philosophy of continual improvement.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_turbosmart.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_turbosmart.png"
              alt="Disc Brakes Australia"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header text="Important Links" font="black" back="white"></page-header>
    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/turbosmart">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1BxqIuAw4uZaBOUtfXl9lBhE8216x3oqs/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>
        <a href="http://www.turbosmart.com/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a
          href="https://drive.google.com/drive/folders/1ZhmfhAH-Wx9Jx-pmHtnnLPIpqLALxoL1"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCiPdbaflth_3zXeQMDHDTEg"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/7xQO_R87jVc"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>
            Turbosmart Ford Fiesta ST - Kompact Shortie Dual Port Blow-Off Valve
            Sound
          </p>
          <p>
            Hear the glorious sound of the Turbosmart Kompact Shortie Dual Port
            blow-off valve (TS-0203-1066), fitted to the Turbosmart Ford Fiesta
            ST. The Kompact Shortie may be small, but it's anything but small on
            sound!
          </p>
        </div>
      </div>
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "../home/Tire";

export default {
  name: "TurbosmartEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
