<template>
  <div>
    <page-header text="O nas" font="white" back="black" />
    <div class="level">
      <div class="text">
        <h3>
          12 LAT DOŚWIADCZENIA MAGAZYNU DYSTRYBUCJI CZĘŚCI DO SAMOCHODÓW 4x4 i
          SPORTOWYCH
        </h3>
        <p>#1</p>
        <p>
          Oz4x4 jest platformą logistyczną i handlową, która koncentruje się na
          dystrybucji wysokiej jakości australijskich i amerykańskich części
          samochodowych w Europie. Dzięki naszemu 12-letniemu doświadczeniu w
          logistyce, magazynowaniu i sprzedaży zapewniamy synergię i ekonomię
          skali zarówno producentom jak i klientom.
        </p>
        <p>
          Firma powstała w 2008 roku, a jej głównym celem jest ułatwienie
          europejskim klientom zakupu marek importowanych, przy jednoczesnym
          ograniczeniu kosztów i czasu transportu. Wieloletnie doświadczenie w
          logistyce i dystrybucji w Europie pozwala nam zaoferować naszym
          klientom konkurencyjne stawki przewozowe oraz niezawodne i szybkie
          warunki dostawy, a także szeroką gamę produktów motoryzacyjnych,
          dzięki czemu mogą oni łączyć swoje zamówienia. Magazyn główny Oz4x4
          jest strategicznie zlokalizowany w Polsce (Toruń). W 2017 roku
          uruchomiliśmy drugi magazyn w Wielkiej Brytanii – Birmingham.
        </p>
      </div>
      <div class="image">
        <picture>
          <source type="image/webp" srcset="./../../assets/warehouse-1.webp" />
          <img src="./../../assets/warehouse-1.jpg" alt="Oz4x4" />
        </picture>
      </div>
    </div>
    <div class="level flipped">
      <div class="text">
        <h3>PROSTY SYSTEM OBSŁUGI</h3>
        <p>#2</p>
        <p>
          Nasi klienci mają dwie możliwości zamawiania swoich produktów. Mogą
          oni korzystać z naszego sklepu internetowego po zalogowaniu się za
          pośrednictwem naszej strony internetowej lub skorzystać z
          zaawansowanego systemu zamówień o nazwie Centrum Obsługi Klienta,
          który daje im szersze możliwości generowania raportów dotyczących
          statusu zamówień i sprzedaży. Oba narzędzia dają naszym klientom
          bezpośredni dostęp do stanów magazynowych i umożliwiają bezpośrednie
          zamawianie produktów na uzgodnionym poziomie rabatowym 48 godziny na
          dobę, 7 dni w tygodniu, a także dostarczają dodatkowych informacji na
          temat alternatywnych opcji wysyłki.
        </p>
      </div>
      <div class="image">
        <picture>
          <source type="image/webp" srcset="./../../assets/warehouse-2.webp" />
          <img src="./../../assets/warehouse-2.jpg" alt="Oz4x4" />
        </picture>
      </div>
    </div>
    <div class="level">
      <div class="text">
        <h3>OBSŁUGA POSPRZEDAŻOWA</h3>
        <p>#3</p>
        <p>
          Sprzedajemy nie tylko produkty wyjątkowe i wysokiej jakości. Nasze
          usługi obejmują również kompleksową obsługę sprzedażową, marketingową
          i posprzedażową, które pomagają nam budować długotrwałą współpracę z
          naszymi klientami.
        </p>
      </div>
      <div class="image">
        <picture>
          <source type="image/webp" srcset="./../../assets/warehouse-3.webp" />
          <img src="./../../assets/warehouse-3.jpg" alt="Oz4x4" />
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "AboutPL",
  components: { PageHeader }
};
</script>
