<template>
  <div>
    <div class="header-wrapper-exception pedders-banner">
      <h2>Pedders</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Pedders is Australia's Number 1 producer of comprehensive range of
            products and ser-vices for all suspension, braking, towing, load
            carrying and steering needs to suit pas-senger cars, four-wheel
            drives, light commercials and high performance vehicles.
          </p>
          <p>
            For each new chassis, Pedders develops an integrated and engineered
            suspension solu-tion. Be it OEM quality replacement parts,
            performance parts for passenger cars or tough off-road suspension we
            are one of the few manufacturers with a holistic solution. Whilst
            Pedders commenced as a shock absorber company, today they are unique
            in that they offer a “system” that they design, manufacture,
            distribute and in Australia also install all under the Pedders
            brand. Pedders “matched” parts include shock absorbers, springs
            (coil, leaf &amp; torsion bar), bushings (polyurethane &amp;
            rubber), chassis parts, hardware (camber pins, bump stops, sway bar
            components, mounts etc.), control arms, trailing arms, power
            steering gears &amp; pumps, CV joints &amp; shafts and even brake
            parts including pads, rotors &amp; drums. All Pedders Branded – a
            SYSTEM.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_pedders.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_pedders.png"
              alt="Pedders"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header text="Important Links" font="black" back="white"></page-header>
    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/pedders">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1Nq_49f6W_vmnHLUW6SCeLd2hZ2tQqKsA/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>
        <a href="https://pedders.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a
          v-if="isUserLogged"
          href="https://drive.google.com/drive/folders/1OvK_7qUnNLeilSQEzggerxDBh4BOiz0P"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/user/PeddersSuspension"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/kxlMVN7_Ix0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Pedders series Introduction - The effect of weight on a vehicle</p>
          <p>
            What do you know about the effect of weight on a vehicle? In the
            first ever Pedders Virtual Techstop, we’ll take you on the inside
            like never before. We’ll point to the issues, problems and
            considerations involved in helping you reach for the best customised
            solution.
          </p>
          <p>www.pedders.com.au</p>
        </div>
      </div>
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "../home/Tire";

export default {
  name: "PeddersEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
