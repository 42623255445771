<template>
  <div>
    <page-header text="Privacy Policy" font="white" back="black" />
    <b-container class="content">
      <p>Ta witryna gromadzi pewne dane osobowe od swoich użytkowników.</p>
      <p>
        Użytkownicy mogą podlegać różnym standardom ochrony i dlatego do
        niektórych mogą mieć zastosowanie szersze standardy. Aby dowiedzieć się
        więcej na temat kryteriów ochrony, użytkownicy mogą zapoznać się z
        częścią poświęconą zasadom stosowania.
      </p>
      <p class="header">Właściciel i kontroler danych</p>
      <p>
        OZPARTS PL sp. z o.o.<br />ul. Słowackiego 32/5<br />87-100, Toruń,
        Polska<br />VAT: PL 956 223 01 29
      </p>
      <p class="header">Inspektor Ochrony Danych</p>
      <p>Przemek Wiśniewski</p>
      <p>Email: privacy.policy@ozparts.eu</p>
      <p class="header">Rodzaje gromadzonych danych</p>
      <p>
        Wśród typów danych osobowych zbieranych przez tę stronę internetową,
        zarówno przez nią samą, jak i za pośrednictwem stron trzecich, znajdują
        się takie dane: Cookies, Dane użytkowe, Dane przekazywane podczas
        korzystania z serwisu, imię, nazwisko, numer telefonu, numer VAT, nazwa
        firmy, adres, kraj, adres e-mail, kod pocztowy/ZIP, miasto i strona www.
      </p>
      <p>
        Pełne informacje na temat każdego rodzaju zbieranych danych osobowych
        znajdują się w dedykowanych sekcjach niniejszej polityki prywatności lub
        w tekstach objaśniających wyświetlanych przed pobraniem danych.
      </p>
      <p>
        Dane osobowe mogą być swobodnie podawane przez Użytkownika lub, w
        przypadku Danych Użytkowania, zbierane automatycznie podczas korzystania
        z Serwisu.
      </p>
      <p>
        O ile nie określono inaczej, wszystkie Dane wymagane przez Serwis są
        obowiązkowe, a niedostarczenie tych Danych może uniemożliwić tej
        Witrynie świadczenie usług. W przypadkach, w których Witryna wyraźnie
        stwierdza, że niektóre Dane nie są obowiązkowe, Użytkownicy mają prawo
        nie przekazywać tych Danych bez konsekwencji dla dostępności lub
        funkcjonowania Usługi.
      </p>
      <p>
        Użytkowników, którzy nie są pewni, które dane osobowe są obowiązkowe,
        zapraszamy do kontaktu z Właścicielem.
      </p>
      <p>
        Jakiekolwiek użycie plików cookie - lub innych narzędzi do śledzenia -
        przez niniejszą Witrynę lub przez właścicieli usług osób trzecich
        wykorzystywanych przez niniejszą Witrynę służy celowi świadczenia Usługi
        wymaganej przez Użytkownika, w uzupełnieniu wszelkich innych celów
        opisanych w niniejszym dokumencie oraz w Polityce dotyczącej plików
        cookie, jeśli są dostępne.
      </p>
      <p>
        Użytkownicy są odpowiedzialni za wszelkie dane osobowe uzyskane,
        opublikowane lub udostępnione przez osoby trzecie za pośrednictwem tej
        strony internetowej i potwierdzają, że posiadają zgodę osoby trzeciej na
        przekazanie Danych Właścicielowi.
      </p>
      <p class="header">Tryb i miejsce przetwarzania danych</p>
      <p class="header">Metody przetwarzania</p>
      <p>
        Właściciel podejmuje odpowiednie środki bezpieczeństwa, aby zapobiec
        nieuprawnionemu dostępowi, ujawnieniu, modyfikacji lub nieuprawnionemu
        zniszczeniu Danych.
      </p>
      <p>
        Przetwarzanie Danych odbywa się przy użyciu komputerów i/lub narzędzi
        informatycznych, zgodnie z procedurami organizacyjnymi i trybami ściśle
        związanymi ze wskazanymi celami. Oprócz Właściciela, w niektórych
        przypadkach, Dane mogą być dostępne dla określonych typów osób
        odpowiedzialnych za funkcjonowanie tej strony internetowej
        (administracja, sprzedaż, marketing, prawo, administracja systemu) lub
        osób zewnętrznych (takich jak zewnętrzni dostawcy usług technicznych,
        operatorzy pocztowi, dostawcy usług hostingowych, firmy informatyczne,
        agencje komunikacyjne, operatorzy płatności elektronicznych Visa,
        MasterCard, Przelewy24, PayPal i inne podmioty przeprowadzające
        rozliczenia, np. KIR, Swift itp.) wyznaczonych, w razie potrzeby, jako
        Przetwarzający Dane przez Właściciela. Aktualna lista tych stron może
        być w każdej chwili żądana od Właściciela.
      </p>
      <p class="header">Podstawa prawna przetwarzania danych</p>
      <p>
        Właściciel może przetwarzać Dane osobowe dotyczące Użytkowników, jeżeli
        ma zastosowanie jedna z poniższych zasad:
      </p>
      <ul>
        <li>
          Użytkownicy wyrazili zgodę na przetwarzanie Danych Osobowych w jednym
          lub kilku konkretnych celach. Uwaga: Zgodnie z niektórymi przepisami
          prawa Właściciel może być upoważniony do przetwarzania Danych
          Osobowych do czasu, gdy Użytkownik nie wyrazi sprzeciwu wobec takiego
          przetwarzania ("opt-out"), bez konieczności polegania na zgodzie lub
          innej z następujących podstaw prawnych. Nie dotyczy to jednak
          sytuacji, gdy przetwarzanie danych osobowych podlega europejskiemu
          prawu ochrony danych;
        </li>
        <li>
          przekazanie Danych jest niezbędne do wykonania umowy z Użytkownikiem
          i/lub wszelkich zobowiązań przedumownych z nim związanych;
        </li>
        <li>
          przetwarzanie jest niezbędne do spełnienia obowiązku prawnego, któremu
          podlega Właściciel;
        </li>
        <li>
          przetwarzanie jest związane z zadaniem wykonywanym w interesie
          publicznym lub w ramach wykonywania władzy publicznej powierzonej
          właścicielowi;
        </li>
        <li>
          przetwarzanie jest konieczne do celów ochrony uzasadnionych interesów
          właściciela lub strony trzeciej
        </li>
      </ul>
      <p>
        W każdym przypadku Właściciel chętnie pomoże w wyjaśnieniu konkretnej
        podstawy prawnej, która ma zastosowanie do przetwarzania, a w
        szczególności, czy podanie danych osobowych jest wymogiem ustawowym lub
        umownym, czy też wymogiem niezbędnym do zawarcia umowy.
      </p>
      <p class="header">Miejsce</p>
      <p>
        Dane przetwarzane są w biurach operacyjnych Właściciela oraz we
        wszystkich innych miejscach, w których znajdują się strony zaangażowane
        w przetwarzanie.
      </p>
      <p>
        W zależności od lokalizacji Użytkownika, przekazywanie danych może
        wiązać się z przekazaniem Danych Użytkownika do kraju innego niż jego
        własny. Aby dowiedzieć się więcej o miejscu przetwarzania przekazywanych
        Danych, Użytkownicy mogą zapoznać się z sekcją zawierającą szczegóły
        dotyczące przetwarzania Danych osobowych.
      </p>
      <p>
        Jeżeli zastosowanie mają szersze standardy ochrony, Użytkownicy mają
        również prawo zapoznać się z podstawą prawną przekazywania danych do
        kraju spoza Unii Europejskiej lub do jakiejkolwiek organizacji
        międzynarodowej prawa publicznego lub organizacji międzynarodowej
        utworzonej przez dwa lub więcej krajów, takich jak ONZ, oraz ze środkami
        bezpieczeństwa podejmowanymi przez Właściciela w celu ochrony ich
        danych.
      </p>
      <p>
        Jeśli taki transfer ma miejsce, Użytkownicy mogą dowiedzieć się więcej,
        sprawdzając odpowiednie sekcje tego dokumentu lub zapytać Właściciela,
        korzystając z informacji podanych w sekcji kontakt.
      </p>
      <p class="header">Czas przechowywania</p>
      <p>
        Dane osobowe są przetwarzane i przechowywane tak długo, jak długo
        wymagają tego cele, dla których zostały zebrane.
      </p>
      <p>W związku z tym:</p>
      <ul>
        <li>
          Dane osobowe zbierane w celach związanych z realizacją umowy pomiędzy
          Właścicielem a Użytkownikiem są przechowywane do czasu pełnego
          wykonania umowy.
        </li>
        <li>
          Dane osobowe zbierane na potrzeby uzasadnionego interesu Właściciela
          będą przechowywane tak długo, jak długo będą potrzebne do realizacji
          tych celów. Użytkownicy mogą znaleźć konkretne informacje dotyczące
          uzasadnionych interesów realizowanych przez Właściciela w odpowiednich
          częściach niniejszego dokumentu lub kontaktując się z Właścicielem.
        </li>
      </ul>
      <p>
        Właściciel może mieć prawo do dłuższego przechowywania Danych Osobowych
        w przypadku wyrażenia przez Użytkownika zgody na ich przetwarzanie, o
        ile zgoda taka nie zostanie cofnięta. Ponadto Właściciel może być
        zobowiązany do dłuższego przechowywania Danych Osobowych, gdy jest to
        wymagane do wykonania zobowiązania prawnego lub na polecenie organu
        władzy.
      </p>
      <p>
        Po upływie okresu przechowywania, dane osobowe zostaną usunięte. Dlatego
        też prawo dostępu, prawo do usunięcia, prawo do sprostowania i prawo do
        przenoszenia danych nie może być egzekwowane po upływie okresu
        przechowywania.
      </p>
      <p class="header">Cele przetwarzania</p>
      <p>
        Dane dotyczące Użytkownika zbierane są w celu umożliwienia Właścicielowi
        świadczenia Usług, jak również w następujących celach: Analizy,
        Wyświetlanie treści z platform zewnętrznych, Interakcja z platformami
        czatu na żywo oraz Kontakt z Użytkownikiem.
      </p>
      <p>
        Dalsze szczegółowe informacje o takich celach przetwarzania oraz o
        konkretnych danych osobowych wykorzystywanych w każdym z tych celów
        można znaleźć w odpowiednich częściach niniejszego dokumentu.
      </p>
      <p class="header">
        Szczegółowe informacje dotyczące przetwarzania danych osobowych
      </p>
      <p>
        Dane osobowe gromadzone są w następujących celach i przy wykorzystaniu
        następujących usług:
      </p>
      <ul>
        <li>
          <p class="header">Analityka</p>
          <p>
            Usługi zawarte w tej sekcji umożliwiają Właścicielowi monitorowanie
            i analizę ruchu w sieci i mogą być wykorzystywane do śledzenia
            zachowań użytkowników.
          </p>
          <p class="header">Google Analytics (Google Inc.)</p>
          <p>
            Google Analytics jest usługą analizy stron internetowych świadczoną
            przez Google Inc. "("Google"). Google wykorzystuje zebrane Dane do
            śledzenia i badania korzystania z tej strony internetowej,
            przygotowywania raportów dotyczących jej działalności i
            udostępniania ich innym usługom Google.
          </p>
          <p>
            Google może wykorzystywać zebrane Dane do kontekstualizacji i
            personalizacji reklam własnej sieci reklamowej.
          </p>
          <p>
            Zebrane dane osobowe: Pliki cookie i dane dotyczące użytkowania.
          </p>
          <p>
            Miejsce przetwarzania danych: Stany Zjednoczone –
            <a
              href="https://www.google.com/intl/en/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >Polityka prywatności</a
            >
            –
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=en"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >Rezygnacja</a
            >. Partycypant ochrony prywatności.
          </p>
        </li>
        <li>
          <p class="header">Kontakt z użytkownikiem</p>
          <p class="header">Formularz kontaktowy (ta strona internetowa)</p>
          <p>
            Wypełniając formularz kontaktowy ze swoimi danymi, Użytkownik
            upoważnia niniejszą stronę internetową do wykorzystania tych danych
            w celu udzielenia odpowiedzi na zapytania o informacje, oferty lub
            innego rodzaju zapytania wskazane w nagłówku formularza.
          </p>
          <p>
            Zebrane dane osobowe: adres, miasto, nazwa firmy, kraj, adres
            e-mail, imię, nazwisko, numer telefonu, numer VAT, strona
            internetowa oraz kod pocztowy/ZIP.
          </p>
        </li>
        <li>
          <p class="header">Wyświetlanie treści z platform zewnętrznych</p>
          <p>
            Ten typ usługi pozwala na przeglądanie treści umieszczonych na
            zewnętrznych platformach bezpośrednio ze stron tej witryny i
            interakcję z nimi.
          </p>
          <p>
            Ten typ usługi może nadal zbierać dane o ruchu na stronach, na
            których usługa jest zainstalowana, nawet jeśli Użytkownicy z niej
            nie korzystają.
          </p>
          <p class="header">Google Fonts (Google Inc.)</p>
          <p>
            Google Fonts jest usługą wizualizacji czcionki dostarczaną przez
            Google Inc., która pozwala tej stronie internetowej na włączenie
            tego rodzaju treści do swoich stron.
          </p>
          <p>
            Zebrane dane osobowe: Korzystanie z Danych i różnych typów Danych
            określonych w polityce prywatności serwisu.
          </p>
          <p>
            Miejsce przetwarzania danych: Stany Zjednoczone –
            <a
              href="https://www.google.it/intl/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >Polityka prywatności</a
            >. Partycypant ochrony prywatności.
          </p>
        </li>
        <li>
          <p class="header">Interakcja z platformami czatu na żywo</p>
          <p>
            Ten typ usługi umożliwia Użytkownikom interakcję z platformami
            czatów na żywo innych firm bezpośrednio ze stron tej Witryny w celu
            skontaktowania się i kontaktu z nimi za pośrednictwem tej usługi
            wsparcia Witryny.
          </p>
          <p>
            Jeśli jedna z tych usług jest zainstalowana, może ona zbierać dane
            dotyczące przeglądania i użytkowania na stronach, na których jest
            zainstalowana, nawet jeśli Użytkownicy nie korzystają aktywnie z
            usługi. Ponadto, rozmowy na czacie na żywo mogą być rejestrowane.
          </p>
          <p class="header">LiveAgent (Quality Unit, LLC)</p>
          <p>
            LiveAgent to połączenie czatu na żywo, systemu obsługi zgłoszeń i
            automatyzacji wsparcia klientów.
          </p>
          <p>
            Zebrane dane osobowe: Cookies, Dane przekazywane podczas korzystania
            z serwisu, Dane użytkowe oraz różnego rodzaju Dane określone w
            polityce prywatności serwisu.
          </p>
          <p>
            Miejsce przetwarzania: Niemcy, Wielka Brytania, Słowacja –
            <a
              href="https://www.liveagent.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >Polityka Prywatności</a
            >.
          </p>
        </li>
      </ul>
      <p class="header">Prawa Użytkowników</p>
      <p>
        Użytkownicy mogą korzystać z pewnych praw w odniesieniu do swoich Danych
        przetwarzanych przez Właściciela.
      </p>
      <p>
        Użytkownicy uprawnieni do szerszych standardów ochrony mogą korzystać z
        któregokolwiek z praw opisanych poniżej. We wszystkich innych
        przypadkach Użytkownicy mogą zwrócić się do Właściciela z zapytaniem,
        jakie prawa ich dotyczą.
      </p>
      <p>W szczególności Użytkownicy mają prawo do:</p>
      <ul>
        <li>
          <strong>Cofnięcie zgody w każdej chwili.</strong> Użytkownicy mają
          prawo do cofnięcia zgody w przypadku, gdy uprzednio wyrazili zgodę na
          przetwarzanie ich Danych osobowych.
        </li>
        <li>
          <strong
            >Wyrażenie sprzeciwu wobec przetwarzania ich danych
            osobowych.</strong
          >
          Użytkownicy mają prawo sprzeciwić się przetwarzaniu ich Danych, jeżeli
          przetwarzanie odbywa się na podstawie prawnej innej niż zgoda. Dalsze
          szczegóły znajdują się w dedykowanej sekcji poniżej.
        </li>
        <li>
          <strong>Dostęp do swoich Danych.</strong> Użytkownicy mają prawo
          dowiedzieć się, czy Dane są przetwarzane przez Właściciela, uzyskać
          ujawnienie pewnych aspektów przetwarzania oraz uzyskać kopię
          przetwarzanych Danych.
        </li>
        <li>
          <strong>Weryfikacja i korekta danych.</strong> Użytkownicy mają prawo
          do weryfikacji poprawności swoich danych i żądania ich aktualizacji
          lub poprawienia.
        </li>
        <li>
          <strong>Ograniczyć przetwarzanie swoich Danych.</strong> Użytkownicy
          mają prawo, w pewnych okolicznościach, do ograniczenia przetwarzania
          ich Danych. W takim przypadku Właściciel nie będzie przetwarzał swoich
          Danych w żadnym innym celu niż ich przechowywanie.
        </li>
        <li>
          <strong>Usunąć lub w inny sposób usunąć swoje dane osobowe.</strong>
          Użytkownicy mają prawo, w pewnych okolicznościach, do uzyskania
          usunięcia swoich danych osobowych od Właściciela.
        </li>
        <li>
          <strong
            >Otrzymują swoje dane i przekazują je do innego kontrolera.</strong
          >
          Użytkownicy mają prawo do otrzymywania swoich Danych w uporządkowanym,
          powszechnie używanym i nadającym się do odczytu maszynowego formacie
          oraz, jeżeli jest to technicznie wykonalne, do przekazania ich innemu
          kontrolerowi bez żadnych przeszkód. Przepis ten ma zastosowanie pod
          warunkiem, że Dane przetwarzane są w sposób zautomatyzowany i że
          przetwarzanie odbywa się za zgodą Użytkownika, na podstawie umowy,
          której częścią jest Użytkownik lub na podstawie jego zobowiązań przed
          zawarciem umowy.
        </li>
        <li>
          <strong>Złożyć skargę.</strong> Użytkownicy mają prawo do wniesienia
          skargi do właściwego organu ochrony danych.
        </li>
      </ul>
      <p class="header">
        Szczegóły dotyczące prawa do sprzeciwu wobec przetwarzania
      </p>
      <p>
        W przypadku, gdy Dane Osobowe przetwarzane są w interesie publicznym, w
        ramach wykonywania władzy publicznej przysługującej Właścicielowi lub w
        celu realizacji uzasadnionego interesu realizowanego przez Właściciela,
        Użytkownicy mogą sprzeciwić się takiemu przetwarzaniu, przedstawiając
        uzasadnienie sprzeciwu w odniesieniu do ich szczególnej sytuacji.
      </p>
      <p>
        Użytkownicy muszą wiedzieć, że jeżeli jednak ich Dane Osobowe będą
        przetwarzane w celach marketingu bezpośredniego, mogą w każdej chwili
        sprzeciwić się takiemu przetwarzaniu bez podania żadnego uzasadnienia.
        Aby dowiedzieć się, czy Właściciel przetwarza Dane Osobowe w celach
        marketingu bezpośredniego, Użytkownicy mogą odwołać się do odpowiednich
        części niniejszego dokumentu.
      </p>
      <p class="header">Jak korzystać z tych praw</p>
      <p>
        Wszelkie wnioski o skorzystanie z praw użytkownika mogą być kierowane do
        Właściciela za pośrednictwem danych kontaktowych podanych w niniejszym
        dokumencie. Żądania te mogą być wykonywane bezpłatnie i będą kierowane
        przez Właściciela tak wcześnie jak to możliwe i zawsze w ciągu jednego
        miesiąca.
      </p>
      <p class="header">Stosowanie szerszych norm ochrony</p>
      <p>
        Podczas gdy większość postanowień niniejszego dokumentu dotyczy
        wszystkich Użytkowników, niektóre postanowienia stosuje się wyraźnie
        tylko wtedy, gdy przetwarzanie Danych osobowych podlega szerszym
        standardom ochrony.
      </p>
      <p>
        Takie szersze standardy ochrony mają zastosowanie w przypadku
        przetwarzania:
      </p>
      <ul>
        <li>jest wykonywany przez Właściciela z siedzibą w UE;</li>
        <li>
          dotyczy Danych osobowych Użytkowników, którzy znajdują się w UE i jest
          związana z oferowaniem odpłatnych lub nieodpłatnych towarów lub usług
          takim Użytkownikom;
        </li>
        <li>
          dotyczy Danych osobowych Użytkowników, którzy znajdują się w UE i
          pozwala Właścicielowi monitorować zachowania takich Użytkowników
          mające miejsce w UE.
        </li>
      </ul>
      <p class="header">
        Dodatkowe informacje na temat gromadzenia i przetwarzania danych
      </p>
      <p class="header">Podejmowanie działań prawnych</p>
      <p>
        Dane osobowe Użytkownika mogą być wykorzystywane w celach prawnych przez
        Właściciela w sądzie lub w etapach prowadzących do ewentualnych działań
        prawnych wynikających z niewłaściwego korzystania z Serwisu lub
        powiązanych Usług.
      </p>
      <p>
        Użytkownik oświadcza, że jest świadomy, iż Właściciel może być
        zobowiązany do ujawnienia danych osobowych na żądanie władz publicznych.
      </p>
      <p class="header">
        Dodatkowe informacje na temat Danych osobowych Użytkownika
      </p>
      <p>
        Oprócz informacji zawartych w niniejszej polityce prywatności, Witryna
        ta może dostarczać Użytkownikowi dodatkowych i kontekstowych informacji
        dotyczących poszczególnych Usług lub gromadzenia i przetwarzania Danych
        osobowych na żądanie.
      </p>
      <p class="header">Rejestry systemowe i konserwacja</p>
      <p>
        Dla celów operacyjnych i konserwacyjnych, ta strona internetowa i
        wszelkie usługi stron trzecich mogą zbierać pliki, które rejestrują
        interakcję z tą stroną internetową (logi systemu) wykorzystują w tym
        celu inne dane osobowe (takie jak adres IP).
      </p>
      <p class="header">Informacje niezawarte w niniejszej polityce</p>
      <p>
        Więcej szczegółów dotyczących gromadzenia lub przetwarzania Danych
        osobowych można uzyskać od Właściciela w dowolnym momencie. Informacje
        kontaktowe znajdują się na początku niniejszego dokumentu.
      </p>
      <p class="header">Jak obsługiwane są żądania "Nie śledź".</p>
      <p>Ta Witryna Internetowa nie obsługuje żądań "Nie śledź".</p>
      <p>
        Aby ustalić, czy którakolwiek z usług stron trzecich, z których
        korzysta, spełnia żądania "Nie śledź", prosimy o zapoznanie się z ich
        polityką prywatności.
      </p>
      <p class="header">Zmiany w niniejszej polityce prywatności</p>
      <p>
        Właściciel zastrzega sobie prawo do wprowadzania zmian w niniejszej
        polityce prywatności w dowolnym momencie poprzez powiadomienie swoich
        Użytkowników na tej stronie i ewentualnie w ramach tej strony i/lub - w
        zakresie, w jakim jest to technicznie i prawnie możliwe - poprzez
        przesłanie Użytkownikom powiadomienia za pośrednictwem wszelkich
        informacji kontaktowych dostępnych Właścicielowi. Zaleca się częste
        sprawdzanie tej strony, odnosząc się do daty ostatniej modyfikacji
        podanej na dole.
      </p>
      <p>
        W przypadku, gdy zmiany mają wpływ na czynności przetwarzania wykonywane
        na podstawie zgody Użytkownika, Właściciel odbierze od Użytkownika nową
        zgodę, o ile jest to wymagane.
      </p>
      <p class="header">Definicje i odniesienia prawne</p>
      <p class="header">Dane osobowe (lub dane)</p>
      <p>
        Wszelkie informacje, które bezpośrednio, pośrednio lub w związku z
        innymi informacjami - w tym osobisty numer identyfikacyjny - pozwalają
        na identyfikację lub identyfikowalność osoby fizycznej.
      </p>
      <p class="header">Dane Użytkowe</p>
      <p>
        Informacje zbierane automatycznie za pośrednictwem tej strony
        internetowej (lub usług osób trzecich wykorzystywanych na tej stronie
        internetowej), które mogą obejmować: adresy IP lub nazwy domen
        komputerów używanych przez Użytkowników korzystających z tej strony
        internetowej, adresy URI (Uniform Resource Identifier), czas złożenia
        wniosku, sposób przekazywania wniosku do serwera, rozmiar pliku
        otrzymanego w odpowiedzi, kod numeryczny wskazujący status odpowiedzi
        serwera (pomyślny wynik, błąd, itd.).), kraj pochodzenia, funkcje
        przeglądarki i systemu operacyjnego używanego przez Użytkownika, różne
        szczegóły dotyczące czasu każdej wizyty (np, czas spędzony na każdej
        stronie w ramach Serwisu) oraz szczegóły dotyczące ścieżki przebytej w
        ramach Serwisu, ze szczególnym uwzględnieniem kolejności odwiedzanych
        stron oraz innych parametrów dotyczących systemu operacyjnego urządzenia
        i/lub środowiska IT Użytkownika.
      </p>
      <p class="header">Użytkownik</p>
      <p>
        Osoba korzystająca z tej strony internetowej, która, o ile nie określono
        inaczej, zbiega się z osobą, której dane dotyczą.
      </p>
      <p class="header">Osoba, której dane dotyczą</p>
      <p>Osoba fizyczna, do której odnoszą się dane osobowe.</p>
      <p class="header">Przetwarzający Dane (lub Inspektor Danych)</p>
      <p>
        Osoba fizyczna lub prawna, władza publiczna, agencja lub inny organ
        przetwarzający Dane osobowe w imieniu Kontrolera, jak opisano w
        niniejszej polityce prywatności.
      </p>
      <p class="header">Kontroler danych (lub Właściciel)</p>
      <p>
        Osoba fizyczna lub prawna, władza publiczna, agencja lub inny organ,
        który samodzielnie lub wspólnie z innymi określa cele i środki
        przetwarzania danych osobowych, w tym środki bezpieczeństwa dotyczące
        działania i korzystania z tej strony internetowej. Właścicielem
        niniejszej strony internetowej jest Kontroler Danych, o ile nie
        określono inaczej.
      </p>
      <p class="header">Ta strona internetowa</p>
      <p>Sposób zbierania i przetwarzania Danych osobowych Użytkownika.</p>
      <p class="header">Usługa</p>
      <p>
        Usługa świadczona za pośrednictwem niniejszej strony internetowej
        zgodnie z opisem w warunkach względnych (jeśli są dostępne) oraz na
        niniejszej stronie/aplikacji.
      </p>
      <p class="header">Unia Europejska (lub UE)</p>
      <p>
        O ile nie określono inaczej, wszystkie odniesienia w niniejszym
        dokumencie do Unii Europejskiej obejmują wszystkie obecne państwa
        członkowskie Unii Europejskiej i Europejskiego Obszaru Gospodarczego.
      </p>
      <p class="header">Cookies</p>
      <p>Małe zestawy danych przechowywane w urządzeniu Użytkownika.</p>
      <hr />
      <p class="header">Informacje prawne</p>
      <p>
        Niniejsze oświadczenie o ochronie prywatności zostało przygotowane w
        oparciu o przepisy wielu aktów prawnych, w tym art. 13/14 rozporządzenia
        (UE) nr 2016/679 (ogólne rozporządzenie o ochronie danych). 13/14
        rozporządzenia (UE) 2016/679 (ogólne rozporządzenie o ochronie danych).
      </p>
      <p>
        Niniejsza polityka prywatności odnosi się wyłącznie do tej strony
        internetowej, o ile w niniejszym dokumencie nie zaznaczono inaczej.
      </p>
      <p>Ostatnia aktualizacja: 24 maja 2018</p>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "PrivacyPl",
  components: { PageHeader }
};
</script>
