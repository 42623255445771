<template lang="pug">
    div(class="privacy-wrapper")
        privacy-en(v-if="lang === 'en'")
        privacy-pl(v-if="lang === 'pl'")
</template>

<script>
import { mapState } from "vuex";
import PrivacyEn from "@/components/privacy/PrivacyEn.vue";
import PrivacyPl from "@/components/privacy/PrivacyPl.vue";

export default {
  name: "PrivacyView",
  components: { PrivacyEn, PrivacyPl },
  computed: {
    ...mapState(["lang"])
  }
};
</script>

<style lang="scss">
.privacy-wrapper {
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 16px;
  }

  .header {
    line-height: 1.5;
    word-spacing: 5px;
    margin: 20px 0;
    font-family: "Michroma", sans-serif;
    font-size: medium;
  }
}
</style>
