import { render, staticRenderFns } from "./TableDetails.vue?vue&type=template&id=6ec27ea4&scoped=true&"
import script from "./TableDetails.vue?vue&type=script&lang=js&"
export * from "./TableDetails.vue?vue&type=script&lang=js&"
import style0 from "./TableDetails.vue?vue&type=style&index=0&id=6ec27ea4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_pug@2.0.4_vue-template-compiler@2.7.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec27ea4",
  null
  
)

export default component.exports