<template>
  <div class="image">
    <picture>
      <source
        :srcset="
          activePhoto ||
            (photos[0] && photos[0].url) ||
            'https://ozparts.eu/img/photo-holder.f4760517.png'
        "
        :alt="name"
      />
      <source
        type="image/webp"
        srcset="../../assets/photo-holder.webp"
        :alt="name"
      />
      <img class="img" src="../../assets/photo-holder.png" :alt="name" />
    </picture>
    <photo-switcher
      v-if="photos.length > 1"
      :photos="photos"
      @photoClicked="url => changeMainPhoto(url)"
    />
  </div>
</template>

<script>
import PhotoSwitcher from "../PhotoSwitcher.vue";
export default {
  name: "PhotoDetails",
  components: {
    PhotoSwitcher
  },
  props: {
    photos: Array,
    name: String
  },
  data() {
    return {
      activePhoto: ""
    };
  },
  methods: {
    changeMainPhoto(url) {
      this.activePhoto = url;
    }
  }
};
</script>

<style lang="scss" scoped>
.image {
  .photo-switcher {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    img {
      width: 100%;
      height: 100%;
      max-height: 150px;
      object-fit: contain;
      border: 1px solid darkgray;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .img {
    width: 100%;
    height: 380px;
    object-fit: contain;
    border: 1px solid darkgray;
    margin-bottom: 1em;

    @media screen and (min-width: 600px) {
      width: auto;
    }

    @media screen and (min-width: 960px) {
      width: 100%;
    }
  }
}
</style>
