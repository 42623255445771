<template>
  <div class="lower-part">
    <hr class="line" />
    <div class="flex">
      <nav-links />
      <div class="d-flex">
        <router-link
          :to="{ name: 'stock-clearance' }"
          tag="div"
          :class="{ active: $route.name === 'stock-clearance' }"
          class="nav-button nav-button-special"
          >{{ $t("deadstock") }}</router-link
        >
        <social-icons />
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcons from "./lower/SocialIcons";
import NavLinks from "./lower/NavLinks";

export default {
  name: "NavLowerPart",
  components: {
    SocialIcons,
    NavLinks
  }
};
</script>

<style lang="scss" scoped>
.link-active {
  background-color: var(--red);
  color: white;
}

.nav-button {
  word-spacing: 2px;
}

.flex {
  display: flex;
  justify-content: space-between;
  z-index: 200;
}

.line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0.3em 0;
  padding: 0;
}

.nav-button-special {
  margin-right: 10px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  &.active {
    background-color: var(--primary);
    color: white;
  }
}
</style>
