<template>
  <div class="general-content">
    <ol>
      <li>
        <p class="header">Czy wysłaliście moją przesyłkę?</p>
        <p>
          Jeśli otrzymałeś fakturę z naszego systemu (wysłaną na adres e-mail,
          który jest ustawiony jako główny w naszym systemie) oznacza to, że
          Twoja przesyłka opuściła nasz magazyn. Nazwa przewoźnika i numer
          przesyłki znajdują się na fakturze (w prawym górnym rogu). Więc jeśli
          chcesz sprawdzić postęp przesyłki, prosimy o zapoznanie się z
          poniższymi instrukcjami:
        </p>
        <p>
          UPS: prosimy otworzyć stronę:
          <a href="http://www.ups.com/content/pl/en/index.jsx" target="_blank"
            >http://www.ups.com/content/pl/en/index.jsx</a
          >
          <br />Proszę wpisać pełny numer śledzenia tak jak na fakturze.
          Zobaczysz aktualne informacje o śledzeniu.
        </p>
        <p>
          EMS/Pocztex: UPS: prosimy otworzyć stronę:
          <a href="http://www.pocztex.pl/dokl_inf_telef.php" target="_blank"
            >http://www.pocztex.pl/dokl_inf_telef.php</a
          >
          <br />Proszę wpisać pełny numer monitorowania jak na fakturze (lub
          wysłanej osobno) w polu "indentyfikator" i nacisnąć "szukaj". Możesz
          również przewinąć w dół i kliknąć na swój kraj, aby przejść do usługi
          śledzenia oferowanej przez lokalnego dostawcę usług EMS.
        </p>
      </li>
      <li>
        <p id="return" class="header">Czy mogę zwrócić zakupiony przedmiot?</p>
        <p>
          Jeśli nie jesteś zadowolony z zakupu, proszę postępuj zgodnie z
          poniższymi instrukcjami, aby zwrócić produkt.
        </p>
        <ol>
          <li>
            <p>
              Zwroty są przyjmowane w ciągu 30 dni od daty zakupu (daty
              wystawienia faktury).
            </p>
          </li>
          <li>
            <p>
              Wszystkie zwroty muszą znajdować się w stanie nienaruszonym,
              oryginalnie zapakowane (bez oznak otwarcia) i zawierać wszystkie
              oryginalne opakowania i/lub etykiety producenta.
            </p>
          </li>
          <li>
            <p>
              Jeżeli części zostały zakupione jako zestaw, nie ma mozliwości
              zwrotu pojedynczych jego składowych. W takich przypadkach istnieje
              możliwość zwrotu tylko całego zestawu zawierające wszystkie jego
              składowe. Zwroty pojedynczych składowych zestawów będą odrzucane i
              odsyłane na koszt kupującego.
            </p>
          </li>
          <li>
            <p>
              Przed zwrotem jakichkolwiek produktów napisz do nas na
              <a href="mailto:support@oz4x4.eu">support@oz4x4.eu</a>
            </p>
          </li>
          <li>
            <p>
              Ważne! Upewnij się, że wypełniony formularz zwrotu został
              załączony do przesyłki zwrotnej (możesz go pobrać poniżej) wraz z
              kopią faktury, a zwrot został do nas odesłany nie później niż w
              ciągu 30 dni od dnia zakupu (wystawienia faktury).
            </p>
            <ul>
              <li>
                <p>
                  Możesz pobrać nasz
                  <a
                    href="http://dba4x4.home.pl/warranty/return/"
                    target="_blank"
                    >formularz zwrotu</a
                  >
                  . Wypełnij go podając swoje dane, numer części i wyjaśnić
                  powód zwrotu.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              Wszelkie zwroty towarów nie zatwierdzone przez nas będą odrzucane.
              Będziesz odpowiedzialny za wysyłkę do nas i z powrotem do Ciebie,
              więc przed podjęciem jakichkolwiek działań skontaktuj się z nami.
            </p>
          </li>
          <li>
            <p>
              Wysyłając zwracane produkty, jesteś odpowiedzialny za prawidłowe
              zapakowanie i wysłanie produktów, tak aby nie uległy one
              uszkodzeniu lub zagubieniu podczas transportu.
            </p>
          </li>
          <li>
            <p>
              Po otrzymaniu zwrotu, nota kredytowa zostanie wystawiona
              natychmiast po otrzymaniu przesyłki. Może to jednak potrwać do 5
              dni roboczych (od dnia otrzymania przesyłki). Wartość noty
              kredytowej będzie przechowywana na Twoim koncie jako nadpłata do
              następnego zakupu i będzie po prostu odliczana od następnej
              płatności. Noty kredytowe będą wystawiane w walucie, w której
              została wystawiona faktura.
            </p>
          </li>
          <li>
            <p>
              Prosimy nie zwracać żadnych przedmiotów za pobraniem, nie
              przyjmujemy zwrotów za pobraniem.
            </p>
          </li>
          <li>
            <p>
              Prosimy zwracać na adres:
              <br />OZPARTS PL sp. z o.o. <br />Mazowiecka 46-48 <br />87-100
              Toruń, Polska
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p id="warranties" class="header">Jak mogę złożyć reklamację?</p>
        <p>
          Jeśli chcesz złożyć reklamację do produktu zakupionego za
          pośrednictwem Ozparts wybierz markę produktu (z poniższej listy),
          który chcesz reklamować i wypełnij formularz reklamacyjny online.
          Uwaga: wszystkie pola muszą być wypełnione oraz muszą zostać dodane
          zdjęcia. Po wypełnieniu formularza prześlij go. Reklamacja zostanie
          automatycznie przesłana do nas. Po otrzymaniu dokumentu wyślemy Tobie
          numer roszczenia. Jednocześnie zostanie on przekazany do producenta,
          który podejmie decyzję. Jeśli reklamacja zostanie zatwierdzona,
          zostanie wystawiona nota kredytowa lub wysłana część zamienna.
        </p>
        <p>
          <a href="https://ozparts.eu/warranty/ACS_PL" target="_blank"
            >Reklamacja - Australian Clutch Services</a
          >
        </p>
        <p>
          <a href="https://ozparts.eu/warranty/DBA_PL" target="_blank"
            >Reklamacja - Disc Brakes Australia</a
          >
        </p>
        <p>
          <a href="https://ozparts.eu/warranty/HAWK_PL" target="_blank"
            >Reklamacja - Hawk Performance</a
          >
        </p>
        <p>
          <a href="https://ozparts.eu/warranty/TURBOSMART_PL" target="_blank"
            >Reklamacja - Turbosmart</a
          >
        </p>
        <p>
          <a href="https://ozparts.eu/warranty/PEDDERS_PL" target="_blank"
            >Reklamacja - Pedders Suspension</a
          >
        </p>
        <p>
          <a href="https://ozparts.eu/warranty/WHITELINE_PL" target="_blank"
            >Reklamacja - Whiteline</a
          >
        </p>
        <p>
          <a href="https://ozparts.eu/warranty/XFORCE_PL" target="_blank"
            >Reklamacja - Xforce</a
          >
        </p>
      </li>
      <li>
        <p class="header">
          Dlaczego zamówienie złożone dzisiaj nie zostało wysłane?
        </p>
        <p>
          Wszystkie zamówienia złożone za pośrednictwem naszego systemu i
          opłacone do godziny 14:00 wysyłane są tego samego dnia. Jeśli
          zamówienie zostało opłacone po godzinie 14:00, zostanie wysłane w
          następnym dniu roboczym.
        </p>
      </li>
      <li>
        <p id="payment" class="header">Jak mogę dokonać płatności?</p>
        <p>
          Płatność za towar może być dokonana za pomocą karty kredytowej /
          płatniczej obsługiwanej za pośrednictwem platromy
          <a href="https://www.paypal.me/Ozparts" target="_blank">PayPal</a>
        </p>
      </li>
      <li>
        <p id="shipping" class="header">Opcje wysyłki</p>
        <p>Aby dostarczyć Państwa towary korzystamy z usług tej firmy:</p>
        <p>
          UPS Standard, UPS Express (dostawa następnego dnia) - zamówienia
          złożone online do godziny 14:00 są przetwarzane tego samego dnia
        </p>
        <figure class="figure">
          <img :src="ups" alt="UPS" />
          <figcaption>*Źródło: www.ups.com</figcaption>
        </figure>
        <table class="table table-striped table-bordered">
          <tbody>
            <tr>
              <th>Kraj:</th>
              <th>Szacunkowy czas realizacji zamówienia (dni robocze)</th>
            </tr>
            <tr>
              <td>Niemcy, Czechy, Słowacja</td>
              <td>2 dni</td>
            </tr>
            <tr>
              <td>Francja, Belgia, Dania, Holandia, Austria, Szwajcaria</td>
              <td>3 dni</td>
            </tr>
            <tr>
              <td>
                Wielka Brytania, Hiszpania, Włochy, Słowenia, Węgry, Rumunia
              </td>
              <td>4 dni</td>
            </tr>
            <tr>
              <td>
                Portugalia, Grecja, Chorwacja, Litwa, Łotwa, Estonia, Norwegia
              </td>
              <td>5 dni</td>
            </tr>
          </tbody>
        </table>
        <p>DPD</p>
        <table class="table table-striped table-bordered">
          <tbody>
            <tr>
              <th>Kraj:</th>
              <th>Szacunkowy czas realizacji zamówienia (dni robocze)</th>
            </tr>
            <tr>
              <td>Belgia</td>
              <td>3 dni</td>
            </tr>
            <tr>
              <td>Bułgaria</td>
              <td>5 dni</td>
            </tr>
            <tr>
              <td>Czechy</td>
              <td>3 dni</td>
            </tr>
            <tr>
              <td>Finlandia</td>
              <td>5 dni</td>
            </tr>
            <tr>
              <td>Francja</td>
              <td>4 dni</td>
            </tr>
            <tr>
              <td>Niemcy</td>
              <td>3 dni</td>
            </tr>
            <tr>
              <td>Grecja</td>
              <td>6 dni</td>
            </tr>
            <tr>
              <td>Włochy</td>
              <td>5 dni</td>
            </tr>
            <tr>
              <td>Holandia</td>
              <td>3 dni</td>
            </tr>
            <tr>
              <td>Polska</td>
              <td>1 dzień</td>
            </tr>
            <tr>
              <td>Portugalia</td>
              <td>5 dni</td>
            </tr>
            <tr>
              <td>Rumunia</td>
              <td>4 dni</td>
            </tr>
            <tr>
              <td>Hiszpania</td>
              <td>5 dni</td>
            </tr>
            <tr>
              <td>Szwecja</td>
              <td>4 dni</td>
            </tr>
          </tbody>
        </table>
        <p>InPost</p>
        <table class="table table-striped table-bordered">
          <tbody>
            <tr>
              <th>Kraj:</th>
              <th>Szacunkowy czas realizacji zamówienia (dni robocze)</th>
            </tr>
            <tr>
              <td>Polska</td>
              <td>1 dzień</td>
            </tr>
          </tbody>
        </table>
      </li>
    </ol>
  </div>
</template>

<script>
import ups from "@/assets/UPS.png";

export default {
  name: "FAQPL",
  data() {
    return {
      ups
    };
  }
};
</script>

<style lang="scss">
.faq-wrapper {
  .general-content {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
  }

  .figure {
    display: block;

    img {
      margin: 0 auto;
    }

    figcaption {
      text-align: center;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  table {
    width: 100%;
    margin: 10px 0;
  }

  p {
    font-size: 16px;
    font-weight: 300;
  }

  .header {
    font-family: "Michroma", sans-serif;
    word-spacing: 5px;
  }
}
</style>
