<template>
  <div class="stock-clerance-wrapper">
    <page-header text="Stock Clearance" back="black" font="white" />
    <div class="stock-clearance-content">
      <b-container>
        <img class="banner" alt="Osram Sale" src="../assets/Osram_Sale.jpg"/>
        <v-progress-circular
          v-if="showLoader"
          indeterminate
          class="loader"
        ></v-progress-circular>
        <div v-else>
          <div class="results">
            <item-card v-for="item in items" :key="item._id" :data="item" />
          </div>
        </div>
        <universal-modal :modal="modal" @closeModal="closeModal"
      /></b-container>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/common/PageHeader";
import ItemCard from "../components/common/ItemCard";
import UniversalModal from "../components/common/UniversalModal";
import config from "../3c.config";
import axios3C from "../axios";
export default {
  name: "OsramStockClearance",
  components: { UniversalModal, ItemCard, PageHeader },
  beforeRouteUpdate(to, from, next) {
    this.search();
    next();
  },
  data() {
    return {
      items: [],
      showLoader: false,
      modal: {
        header: "Server error",
        text: "",
        show: false
      }
    };
  },
  created() {
    this.search();
  },
  methods: {
    closeModal() {
      this.modal.show = false;
    },
    error(err) {
      const error = (err.response && err.response.data) || err;
      this.modal.header = "Server error";
      this.modal.text = error.message || error.errmsg || error;
      this.modal.code = error.code;
      this.modal.show = true;
    },
    search() {
      this.showLoader = true;
      let payload = {
        action: "search",
        pricegroups: ["5e0dbaba9e33df43f0b3a4c6"],
        customer: "guest",
        currency: config.CURRENCY
      };
      axios3C
        .post("/search", payload)
        .then(res => {
          this.items = res.data;
        })
        .catch(err => {
          this.error(err);
        })
        .finally(() => {
          this.showLoader = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  margin-bottom: 20px;
}
.loader {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.no-result {
  text-align: center;
  margin: 20px 0;

  &-header {
    font-family: "Michroma", sans-serif;
  }

  &-text {
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
  }
}

.results {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
}
</style>
