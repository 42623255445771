<template>
  <v-dialog v-model="modal.show" max-width="290">
    <v-card>
      <v-card-title class="headline">{{ modal.header }}</v-card-title>
      <v-card-text>{{ modal.text }}</v-card-text>
      <v-card-actions>
        <div></div>
        <v-spacer />
        <v-btn text @click="handleClick">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UniversalModal",
  props: {
    modal: Object
  },
  methods: {
    handleClick() {
      this.$emit("closeModal");
    }
  }
};
</script>
