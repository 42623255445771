<template>
  <div class="table-details">
    <h4 class="header" v-if="axle">{{ name }}</h4>
    <ul>
      <li v-for="item in technicaldetails" :key="item._id">
        <div>{{ item.name }}</div>
        <div v-html="item.value"></div>
      </li>
      <li v-if="axle">
        <div>Axle:</div>
        <div>{{ axle }}</div>
      </li>
      <li v-if="weight">
        <div>Weight [KG]:</div>
        <div>{{ weight }}</div>
      </li>
    </ul>
    <div class="car-image" v-if="axlePicture">
      <img
        v-if="axlePicture === 'Rear'"
        src="./../../../assets/Rear_axle_green.png"
        alt="Rear axle"
      />
      <img
        v-if="axlePicture === 'Front'"
        src="./../../../assets/Front_axle_green.png"
        alt="Front axle"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TableDetails",
  computed: {
    axlePicture() {
      const axle = this.technicaldetails.find(item => item.name === "Axle");
      return (axle && axle.value) || this.axle;
    }
  },
  props: {
    technicaldetails: Array,
    weight: [String, Number],
    axle: String,
    name: String
  }
};
</script>

<style lang="scss" scoped>
.table-details {
  text-align: left;
  ul {
    margin-top: 20px;
    list-style-type: none;
    padding: 0;
    li {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;

      div:last-of-type {
        text-align: right;
      }
    }
  }
  .car-image {
    img {
      width: 100%;
    }
  }
}
</style>
