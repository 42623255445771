const dba = require("./assets/kolecka/Kolecka_DBA.png");
const dbaWebp = require("./assets/kolecka/Kolecka_DBA.webp");
const xo = require("./assets/kolecka/Kolecka_XO.png");
const xoWebp = require("./assets/kolecka/Kolecka_XO.webp");
const hawk = require("./assets/kolecka/o-hawk.jpg");
const hawkWebp = require("./assets/kolecka/o-hawk.webp");
const pedders = require("./assets/kolecka/o-pedders.jpg");
const peddersWebp = require("./assets/kolecka/o-pedders.webp");
const turbosmart = require("./assets/kolecka/o-turbosmart.jpg");
const turbosmartWebp = require("./assets/kolecka/o-turbosmart.webp");
const whiteline = require("./assets/kolecka/o-whiteline.jpg");
const whitelineWebp = require("./assets/kolecka/o-whiteline.webp");
const acl = require("./assets/kolecka/o-acl.png");
const aclWebp = require("./assets/kolecka/o-acl.webp");
const redarc = require("@/assets/kolecka/redarc1_180x180.png");
const redarcWebp = require("@/assets/kolecka/redarc1_180x180.webp");
const saber = require("@/assets/kolecka/saber1_180x180.png");
const saberWebp = require("@/assets/kolecka/saber1_180x180.webp");
const ryco = require("@/assets/kolecka/ryco.png");
const rycoWebp = require("@/assets/kolecka/ryco.webp");
const aeroflow = require("./assets/kolecka/Aeroflow_Ozparts_page.png");
const aeroflowWebp = require("./assets/kolecka/Aeroflow_Ozparts_page.webp");

export const brands = [
  {
    header: "dba",
    img: dba,
    imgWebp: dbaWebp,
    showInDeadstock: true
  },
  {
    header: "xoutback",
    img: xo,
    imgWebp: xoWebp,
    showInDeadstock: true
  },
  {
    header: "hawk",
    img: hawk,
    imgWebp: hawkWebp,
    showInDeadstock: true
  },
  {
    header: "pedders",
    img: pedders,
    imgWebp: peddersWebp,
    showInDeadstock: true
  },
  {
    header: "turbosmart",
    img: turbosmart,
    imgWebp: turbosmartWebp
  },
  {
    header: "whiteline",
    img: whiteline,
    imgWebp: whitelineWebp,
    showInDeadstock: true
  },
  {
    header: "acl",
    img: acl,
    imgWebp: aclWebp,
    showInDeadstock: true
  },
  {
    header: "redarc",
    img: redarc,
    imgWebp: redarcWebp
  },
  {
    header: "saber",
    img: saber,
    imgWebp: saberWebp
  },
  {
    header: "ryco",
    img: ryco,
    imgWebp: rycoWebp
  },
  {
    header: "aeroflow",
    img: aeroflow,
    imgWebp: aeroflowWebp,
    showInDeadstock: true
  }
];

export const MANUFACTURER = {
  ACS: "5f9bda55dcdf6b0c04f18789",
  DBA: "5f9bda55dcdf6b0c04f1878c",
  PEDDERS: "5f9bda55dcdf6b0c04f1878b",
  XFORCE: "5f9bda55dcdf6b0c04f1878d",
  HAWK: "5f9bda56dcdf6b0c04f18790",
  TURBOSMART: "5f9bda56dcdf6b0c04f18791",
  ACL: "5f9bda56dcdf6b0c04f1878e",
  WHITELINE: "5f9bda55dcdf6b0c04f1878a",
  REDARC: "5f9bdaf85e3dc023c4682867",
  SABER: "60c063843f41452f00573370",
  AEROFLOW: "60c0672c3f41452f00573373",
  EUROSPARES: "5f9bda56dcdf6b0c04f18792",
  OSRAM: "5f9bda56dcdf6b0c04f18793",
  RYCO: "65e02c81012bf8a784d9f3b9",
  OTHER: "5f9bda56dcdf6b0c04f18794"
};
