<template>
  <li>
    <div class="li-flex">
      <div>
        <img
          class="avl-img"
          :src="availabilityInfo.image"
          alt="availability"
        /><span class="tool">
          {{ availabilityInfo.text }}
        </span>
        <v-tooltip v-if="availabilityInfo.tooltipText" top class="tool">
          <template v-slot:activator="{ on }">
            <v-icon color="primary" dark v-on="on"
              >mdi-help-circle-outline</v-icon
            >
          </template>
          <span>{{ availabilityInfo.tooltipText }}</span>
        </v-tooltip>
      </div>
      <div>{{ availabilityInfo.value }}</div>
    </div>
  </li>
</template>

<script>
export default {
  name: "Availability",
  props: {
    availabilityInfo: Object
  }
};
</script>

<style scoped lang="scss">
.li-flex {
  display: flex;
  justify-content: space-between;
  text-align: left;

  div:last-of-type {
    text-align: right;
  }
}

.avl-img {
  margin-right: 10px;
}

.tool {
  margin-right: 5px;
}
</style>
