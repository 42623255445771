<template>
  <div>
    <cookies-en v-if="lang === 'en'" />
    <cookies-pl v-if="lang === 'pl'" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import CookiesEn from "@/components/cookies/CookiesEn";
import CookiesPl from "@/components/cookies/CookiesPl";

export default {
  name: "CookiesPolicy",
  components: {
    CookiesPl,
    CookiesEn
  },
  computed: {
    ...mapState(["lang"])
  }
};
</script>

<style scoped></style>
