<template>
  <div class="additional-info-wrapper">
    <div class="main-item-header">
      {{ $t("BASKET.ADDITIONAL_INFORMATION") }}
    </div>
    <b-form-textarea
      id="textarea"
      :value="value"
      rows="4"
      @change="handleChange"
    ></b-form-textarea>
  </div>
</template>

<script>
export default {
  name: "AdditionalInfo",
  props: {
    value: String
  },
  methods: {
    handleChange(memo) {
      this.$emit("input", memo);
    }
  }
};
</script>

<style scoped lang="scss">
.main-item-header {
  background-color: black;
  color: white;
  font-size: 16px;
  padding: 5px 15px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: "Michroma", sans-serif;
}

.selected {
  line-height: 12px;
  padding-left: 40px;

  @media screen and (max-width: 960px) {
    padding-left: 0;
    padding-top: 20px;
  }
}
</style>
