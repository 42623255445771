<template>
  <div>
    <div class="header-wrapper-exception redarc-banner">
      <h2>Ryco</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p class="font-weight-bold">
            Ryco's design philosophy aims to meet or exceed original equipment.
            Ryco Filters deliver genuine performance based on efficiency, life
            and flow.
          </p>
          <p>
            Exceeding manufacturing standards with innovative design, Ryco are
            industry leaders in automotive filtration. Ryco test their filters
            in their dedicated laboratory, with curiosity driving them to
            constantly search for smarter solutions in an ever-changing
            industry.
          </p>
          <p>
            Ryco has continued to be a leader in filtration design and
            development for over 80 years and they’ve adapted to the most
            demanding conditions to support their partners' success, whether
            it’s passenger vehicles, commercial vehicles, 4X4 or Supercars.
          </p>
          <p>So, when you want the best in filtration, be Ryco Ready.</p>
          <p class="font-weight-bold">Ryco Guarantee:</p>
          <ul>
            <li>
              <span class="font-weight-bold">Efficiency</span><br />
              <span>The percentage of dirt that a filter removes</span>
            </li>
            <li>
              <span class="font-weight-bold">Life</span><br />
              <span>How long a filter lasts before clogging up</span>
            </li>
            <li>
              <span class="font-weight-bold">Flow</span><br />
              <span>How easily air, oil or fuel flows through the filter</span>
            </li>
          </ul>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/ryco.webp"
            />
            <img src="./../../assets/brands-logos/ryco.webp" alt="Ryco" />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header text="Important Links" font="black" back="white"></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/ryco">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a href="https://rycofilters.com.au/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a href="https://www.youtube.com/@RycoFiltersOfficial" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/L--BQ0vJrvQ?si=J5zmjkeYzzzvKNVe"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Ryco 4WD Service Kits</p>
          <p>
            Our 4WD Service Kits make it easy to buy all the filters your 4x4
            needs in one convenient kit. Designed to meet the demanding needs of
            4x4 vehicles in the most challenging environments, our superior
            filters provide added protection for harsh driving conditions.
          </p>
          <p>
            To find out more about our 4WD Service kits visit
            www.rycofilters.com.au/service-kits
          </p>
        </div>
      </div>
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "@/components/home/Tire";

export default {
  name: "SaberEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
