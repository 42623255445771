<template>
  <div>
    <page-header text="About us" font="white" back="black" />
    <div class="level">
      <div class="text">
        <h3>12 YEARS OF PERFORMANCE AND 4X4 PARTS DISTRIBUTION WAREHOUSE</h3>
        <p>#1</p>
        <p>
          Oz4x4 offers high-quality Australian & US automotive components to
          European customers. Thanks to over 12 years of experience with
          logistics, warehousing and sales, we have built a wide range of parts
          not only for motorsport but also upgraded experience from daily street
          driving. Our range includes brake discs and pads (DBA, Hawk), clutches
          (Xtreme Performance Clutch), suspension (Pedders), race bearings (ACL
          Race Series), turbo accessories (Turbosmart), stability control
          components (Whiteline) and exhaust systems (Xforce).
        </p>
      </div>
      <div class="image">
        <picture>
          <source type="image/webp" srcset="./../../assets/warehouse-1.webp" />
          <img src="./../../assets/warehouse-1.jpg" alt="Oz4x4 Warehouse" />
        </picture>
      </div>
    </div>
    <page-header text="Our brands" />
    <tire />
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import Tire from "../home/Tire";

export default {
  name: "AboutEN",
  components: { Tire, PageHeader }
};
</script>
