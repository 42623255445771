<template>
  <div>
    <div class="header-wrapper-exception pedders-banner">
      <h2>Pedders</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Pedders jest australijskim liderem w produkcji szerokiej gamy
            produktów i usług w zakresie zawieszeń, układów hamulcowych,
            holowania, przewożenia ładunku i układów kierowniczych dostosowanych
            do samochodów osobowych, z napędem na cztery koła, lekkich pojazdów
            użytkowych i pojazdów o dużej mocy.
          </p>
          <p>
            Dla każdego nowego typu podwozia Pedders opracowuje zintegrowane i
            specjalnie zaprojektowane zawieszenie. Niezależnie od tego, czy są
            to wysokiej jakości części zamienne OEM, wydajne części zamienne do
            samochodów osobowych czy wytrzymałe zawieszenie terenowe, jesteśmy
            jednym z niewielu producentów, którzy stosują całościowe
            rozwiązania. Choć Pedders rozpoczął działalność jako firma zajmująca
            się amortyzatorami, dzisiaj jest wyjątkowy, ponieważ oferuje
            unikalny "system", zaprojektowany, produkowany, dystrybuowany i
            instalowany (w Australii) jako marka Pedders. Dopasowane części
            Pedders to amortyzatory, sprężyny (spiralne, płytkowe i drążki
            skrętne), tuleje (poliuretan i guma), części podwozia, osprzęt
            (trzpienie krzywkowe, zderzaki, części składowe drążków
            przechyłowych, uchwyty itp.), wahacze, ramiona sterujące, ramiona
            wleczone, wspomaganie przekładni kierowniczych, przekładnie i pompy,
            przeguby homokinetyczne, wałki homokinetyczne, a nawet części
            hamulcowe, w tym klocki, tarcze i bębny. Wszystkie części
            sprzedawane są jako marka Pedders - "system".
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_pedders.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_pedders.png"
              alt="Pedders"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/pedders">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1Nq_49f6W_vmnHLUW6SCeLd2hZ2tQqKsA/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="https://pedders.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          v-if="isUserLogged"
          href="https://drive.google.com/drive/folders/1OvK_7qUnNLeilSQEzggerxDBh4BOiz0P"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/user/PeddersSuspension"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/kxlMVN7_Ix0"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Pedders series Introduction - The effect of weight on a vehicle</p>
          <p>What do you know about the effect of weight on a vehicle? In the first ever Pedders Virtual Techstop, we’ll take you on the inside like never before. We’ll point to the issues, problems and considerations involved in helping you reach for the best customised solution.</p>
          <p>www.pedders.com.au</p>
        </div>
      </div>-->
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "../home/Tire";

export default {
  name: "PeddersPl",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
