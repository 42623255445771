import { mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  computed: {
    ...mapState(["userCountry", "currency", "lang"]),
    language() {
      if (this.lang === "pl") {
        return "polish";
      } else {
        return "english";
      }
    }
  },
  methods: {
    ...mapMutations(["setUserCountry"]),
    async getItem(record, override = {}) {
      if (!(this.userCountry && this.userCountry.code)) {
        try {
          const res = await axios.get("https://api.ozparts.eu/country");
          this.setUserCountry(res.data);
        } catch (e) {
          this.setUserCountry({ code: "PL", country: "Poland" });
        }
      }
      // eslint-disable-next-line no-undef
      return this.axios3C.post("/item", {
        action: "getItem",
        record,
        customer: "guest",
        country: this.userCountry.code,
        currency: this.currency,
        language: this.language,
        ...override
      });
    },
    getAxle(number, manufacturer = "Disc Brakes Australia Pty Ltd") {
      return this.axiosBase.get(
        "https://dba4x4.home.pl/new-ozparts/prod/scripts/getAxle.php",
        {
          params: {
            number,
            manufacturer
          }
        }
      );
    },
    getApplications(res) {
      if (this.isUserLogged && !res.data.price) {
        this.$store.commit("logOut");
      }
      return this.axios3C
        .post("/applications", {
          action: "getApplications",
          item: res.data.id,
          customer: "guest",
          language: this.lang === "pl" ? "polish" : "english",
          currency: this.currency
        })
        .then(res => {
          this.applications = res.data.results;
        });
    },
    getRelatedItems(res) {
      return Promise.all([
        this.getRelatedItemsFrom3c(res),
        this.getRelatedItemsFromCatalogue(res)
      ]);
    },
    getRelatedItemsFrom3c(res) {
      // eslint-disable-next-line no-undef
      return this.axios3C
        .post("/item", {
          action: "getRelatedItems",
          record: res.data.id,
          customer: "guest",
          currency: this.currency
        })
        .then(res => {
          if (res && res.data) {
            this.object.relateditems = [
              ...this.object.relateditems,
              ...res.data
            ];
          }
        });
    },
    getRelatedItemsFromCatalogue(res) {
      return this.axiosBase
        .get("https://dba4x4.home.pl/new-ozparts/prod/scripts/dbUtils.php", {
          params: {
            number: res.data.number,
            manufacturer: res.data.manufacturer
          }
        })
        .then(res => this.getRelatedItemsDetails(res));
    },
    getRelatedItemsDetails(res) {
      if (res && res.data) {
        const ids = res.data.map(item => item.ns_id);
        // eslint-disable-next-line no-undef
        return this.axios3C
          .post("/item", {
            action: "getItem",
            record: ids,
            customer: "guest",
            currency: this.currency
          })
          .then(res => {
            if (res && res.data) {
              this.object.relateditems = [
                ...this.object.relateditems,
                ...res.data
              ];
            }
          });
      }
    },
    getAcl(res) {
      if (res.data.manufacturer === "ACL Bearing Company") {
        this.axiosBase
          .get(`https://aclperformance.eu/api/part/ozparts/${res.data.name}`)
          .then(res => {
            this.acl = res.data.data;
          });
      }
    },
    getAxleForIndividual(res) {
      const hasAxle = res => {
        if (res.data.manufacturer) {
          return (
            res.data.manufacturer.includes("Disc Brakes Australia") ||
            res.data.manufacturer.includes("DBA") ||
            res.data.name.includes("HB")
          );
        }
      };

      if (hasAxle(res)) {
        this.getAxle(res.data.number, res.data.manufacturer).then(res => {
          this.object.technicaldetails = [
            ...this.object.technicaldetails,
            {
              _id: "0",
              name: "Axle",
              value: res.data
            }
          ];
        });
      }
    },
    getComboIngredients(res) {
      const getComboAxle = res => {
        const first = res.data.components.find(item =>
          item.name.includes("DBA")
        );
        const ns_id = first.number;

        this.getAxle(ns_id).then(res => {
          this.comboAxle = res.data;
        });
      };

      const getComboDetails = res => {
        const ids = res.data.components.map(item => item.id);
        const promises = [];

        ids.forEach(record => {
          promises.push(this.getItem(record));
        });

        return Promise.all(promises).then(values => {
          this.comboComponents = values.map(value => value.data);
        });
      };

      if (
        res.data.recordtype === "kititem" &&
        res.data.manufacturer === "DBA Combo Kit"
      ) {
        return Promise.all([getComboAxle(res), getComboDetails(res)]);
      }
    }
  }
};
