<template>
  <div class="nav-buttons">
    <router-link
      v-for="item in items"
      :key="item.title"
      :to="item.to"
      tag="div"
      active-class="link-active"
      class="nav-button"
      >{{ item.title }}</router-link
    >
  </div>
</template>

<script>
import i18n from "../../../../i18n";

export default {
  name: "NavLinks",
  computed: {
    items() {
      if (i18n.locale === "pl") {
        return [
          { title: "O nas", icon: "star", to: "/about" },
          { title: "Marki", icon: "build", to: "/brands" },
          { title: "Katalog", icon: "list", to: "/catalogue" },
          // { title: "Aktualności", icon: "explore", to: "/news" },
          { title: "Kontakt", icon: "question_answer", to: "/contact" },
          { title: "FAQ", icon: "question_answer", to: "/faq" }
        ];
      } else {
        return [
          { title: "About us", icon: "star", to: "/about" },
          { title: "Catalogues", icon: "list", to: "/catalogue" },
          { title: "Brands", icon: "build", to: "/brands" },
          // { title: "News", icon: "explore", to: "/news" },
          { title: "Contact us", icon: "question_answer", to: "/contact" }
        ];
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
