<template>
  <div>
    <div class="header-wrapper-exception whiteline-banner">
      <h2>Whiteline</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Whiteline's boasts a worldwide reputation as a leading manufacturer
            of replacement, enhancement and performance suspension components
            that deliver significant gains in vehicle balance, steering
            precision, grip levels and outright driving performance.
          </p>
          <p>
            Consisting of adjustable sway bars (also known as Stabilizer Bars or
            Anti Roll Bars), chassis bracing, alignment products and chassis
            control bushing kits, Whiteline products provide automotive
            workshops, tuners, enthusiasts and racers with innovative solutions
            to suspension and handling problems while delivering proven
            performance & problem solving outcomes for many of the worlds
            vehicle platforms.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_whiteline.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_whiteline.png"
              alt="Whiteline"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header text="Important Links" font="black" back="white"></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/whiteline">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/17H3DcHMDuOAbYAwm9P5g80in3iPopH72/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>
        <a href="http://whiteline.com.au/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a
          href="https://drive.google.com/drive/folders/1y_peknJX7beJnyGJy7lvFOsH4O2IiiKD"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a href="https://www.youtube.com/user/Whitelineflatout" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/1sOCSAwyfDg"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Whiteline - Activate More Grip - www.whitelineus.com</p>
          <p>
            Whiteline's boasts a worldwide reputation as a leading manufacturer
            of replacement, enhancement and performance suspension components
            that deliver significant gains in vehicle balance, steering
            precision, grip levels and outright driving performance. Consisting
            of adjustable sway bars (also known as Stabilizer Bars or Anti Roll
            Bars), chassis bracing, alignment products and chassis control
            bushing kits, Whiteline products provide automotive workshops,
            tuners, enthusiasts and racers with innovative solutions to
            suspension and handling problems while delivering proven performance
            &amp; problem solving outcomes for many of the worlds vehicle
            platforms.
          </p>
        </div>
      </div>
      <page-header :text="$t('ourBrands')" />
      <tire />
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";
import Tire from "../home/Tire";

export default {
  name: "WhitelineEn",
  components: { Tire, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
