<template>
  <div
    class="double-table"
    v-if="
      object.recordtype === 'kititem' &&
        object.pricegroup !== '5e0dbaba9e33df43f0b3a4d0' &&
        object.manufacturergroup !== MANUFACTURER.REDARC
    "
  >
    <table-details
      v-for="item in comboComponents"
      :key="item.id"
      :name="item.name"
      :technicaldetails="item.technicaldetails"
      :weight="item.weight"
      :axle="axle"
    />
  </div>
  <div class="double-table" v-else>
    <table-details
      :technicaldetails="object.technicaldetails"
      :weight="object.weight"
    />
  </div>
</template>

<script>
import TableDetails from "./TableDetails";
import { MANUFACTURER } from "../../../vars";

export default {
  name: "TechDetails",
  components: { TableDetails },
  props: {
    object: Object,
    comboComponents: Array,
    axle: String
  },
  data() {
    return {
      MANUFACTURER
    };
  }
};
</script>

<style lang="scss" scoped>
.double-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
}
</style>
