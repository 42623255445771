<template>
  <div class="news-item-wrapper-view">
    <page-header
      :text="(post && post.post_title) || ''"
      font="white"
      back="black"
    />
    <b-container v-if="post">
      <div class="img-wrapper">
        <!--suppress HtmlUnknownTarget -->
        <img :src="post.img_url" :alt="post.post_title" />
      </div>
      <div v-html="post.html"></div>
    </b-container>
  </div>
</template>

<script>
import { axiosWP } from "../axios";
import PageHeader from "@/components/common/PageHeader";

export default {
  name: "NewsItemView",
  components: { PageHeader },
  data() {
    return {
      post: null
    };
  },
  created() {
    const param = new URL(window.location.href);
    const array = param.pathname.split("/");

    axiosWP
      .get("/news/" + array[array.length - 1])
      .then(res => (this.post = res.data));
  }
};
</script>

<style lang="scss">
.news-item-wrapper-view {
  box-sizing: border-box;
  line-height: 2em;
  font-family: Montserrat, sans-serif;

  .img-wrapper {
    margin: 2em 0;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
