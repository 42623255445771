import axios from "axios";
import cfg from "./3c.config";

const axios3C = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

axios3C.interceptors.request.use(
  function(config) {
    if (config.data) {
      config.data.source = cfg.SOURCE;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const axiosWP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_NEWS
});

export default axios3C;

export const axiosBase = axios.create();
