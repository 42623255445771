<template>
  <div
    class="header-wrapper"
    :class="{ stickyClass: sticky }"
    :style="{ 'background-color': back, color: font }"
  >
    <h2>{{ text }}</h2>
    <hr class="line" :style="{ 'border-top': '2px solid ' + font }" />
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    text: String,
    back: String,
    font: String,
    sticky: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.stickyClass {
  position: sticky;
  top: 0;
  z-index: 1;
}

.header-wrapper {
  font-family: "Michroma", sans-serif;
  text-align: center;
  // margin-top: 2em;
  color: black;
  padding: 1em;

  h2 {
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.5;
    word-spacing: 5px;
  }

  .line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid black;
    margin: 1em auto;
    padding: 0;
    width: 7em;
  }
}
</style>
